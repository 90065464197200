import type { CarouselPromptOption, OrderCarouselPromptOption } from '@customers-api-client'

const dateOptions: Intl.DateTimeFormatOptions = {
  year: 'numeric',
  day: 'numeric',
  month: 'long',
}

export const formatDate = (purchaseDate: number): string => {
  const date = new Date(purchaseDate)
  return date.toLocaleDateString('en-US', dateOptions)
}

export const isOrderCarouselOption = (
  option: CarouselPromptOption,
): option is OrderCarouselPromptOption => {
  return option.type === 'order'
}

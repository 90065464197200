import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { Text } from '@customers-ui'
import type { Plan, PlanDetails } from '@customers-api-client'
import { FeatureContent } from './feature-content'
import { getIsAdhPlan } from '../../../utils/plan-helpers'

interface PlanFeaturesProps {
  plan: Plan
  planDetails: PlanDetails
}

const PlanFeatures: FC<PlanFeaturesProps> = ({ plan, planDetails }) => {
  const rundownList = [
    [
      planDetails.planDetails.rundownInfoTitle1,
      planDetails.planDetails.rundownInfoBody1,
      planDetails.planDetails.rundownInfoIcon1,
    ],
    [
      planDetails.planDetails.rundownInfoTitle2,
      planDetails.planDetails.rundownInfoBody2,
      planDetails.planDetails.rundownInfoIcon2,
    ],
    [
      planDetails.planDetails.rundownInfoTitle3,
      planDetails.planDetails.rundownInfoBody3,
      planDetails.planDetails.rundownInfoIcon3,
    ],
    [
      planDetails.planDetails.rundownInfoTitle4,
      planDetails.planDetails.rundownInfoBody4,
      planDetails.planDetails.rundownInfoIcon4,
    ],
    [
      planDetails.planDetails.rundownInfoTitle5,
      planDetails.planDetails.rundownInfoBody5,
      planDetails.planDetails.rundownInfoIcon5,
    ],
    [
      planDetails.planDetails.rundownInfoTitle6,
      planDetails.planDetails.rundownInfoBody6,
      planDetails.planDetails.rundownInfoIcon6,
    ],
  ]

  return (
    <Wrapper>
      <Header>{planDetails.planDetails.rundownHeader}</Header>
      <FeatureList>
        {rundownList.map(([title, content, icon]) => (
          <FeatureListItem key={title}>
            <IconWrapper>
              <img src={icon} alt={title} />
            </IconWrapper>
            <ContentWrapper>
              <FeatureTitle>{title}</FeatureTitle>
              <FeatureContent content={content} />
            </ContentWrapper>
          </FeatureListItem>
        ))}
      </FeatureList>
      {!getIsAdhPlan(plan) && (
        <RundownFootnote>{planDetails.planDetails.rundownFootnote}</RundownFootnote>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.section({
  textAlign: 'center',
  maxWidth: 1076,
  margin: '0 auto',
  marginTop: 48,
  [bp.md]: {
    marginBottom: 65,
    marginTop: 72,
  },
  [bp.lg]: {
    marginTop: 88,
  },
})

const Header = styled.h2({
  fontSize: 26,
  lineHeight: 1,
  margin: 0,
  marginBottom: 29,
  [bp.md]: {
    fontSize: 39,
    marginBottom: 31,
  },
  [bp.lg]: {
    marginBottom: 56,
  },
})

const FeatureList = styled.ul({
  display: 'flex',
  listStyleType: 'none',
  margin: 0,
  justifyContent: 'space-between',
  paddingLeft: 0,
  flexDirection: 'row',
  flexWrap: 'wrap',
  flexBasis: '100%',
  marginTop: 0,
  [bp.lg]: {
    minWidth: 960,
  },
})

const FeatureListItem = styled.li({
  display: 'flex',
  margin: 0,
  textAlign: 'left',
  marginBottom: 32,
  flexBasis: '100%',
  [bp.md]: {
    flexBasis: '46%',
  },
})

const ContentWrapper = styled.div({
  flex: 1,
})

const FeatureTitle = styled.h3({
  margin: 0,
  marginBottom: 6,
  fontSize: 18,
  [bp.md]: {
    marginBottom: 8,
    fontSize: 26,
  },
})

const IconWrapper = styled.div({
  marginRight: 23,
  width: 40,
  // fix for small icon
  '& #accidentalDamage': {
    width: '150%',
    transform: 'translate(-10px, -3px)',
  },
  '& img': {
    width: '100%',
  },
  [bp.md]: {
    marginRight: 40,
  },
})

const RundownFootnote = styled(Text)({
  marginTop: 0,
  marginBottom: 32,
  [bp.md]: {
    marginTop: 32,
    marginBottom: 0,
  },
})

export { PlanFeatures }

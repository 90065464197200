import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Link } from 'react-router-dom'
import { bp } from '@extend/client-helpers'
import type { Contract } from '@customers-api-client'
import type { Locale } from '../../../types/localization'
import { images } from '../../../lib/assets'

const pageText = {
  'en-US': {
    heading: 'Have more questions?',
    body: [
      'You can',
      'contact us here',
      'or call us at ',
      '(877) 248-7707',
      ' between the hours of 7:00 AM and 10:00 PM EST.',
    ],
  },
  'en-CA': {
    heading: 'Have more questions?',
    body: [
      'You can',
      'contact us here',
      'or call us at ',
      '(877) 248-7707',
      ' between the hours of 7:00 AM and 10:00 PM EST.',
    ],
  },
  'fr-CA': {
    heading: `Vous avez d'autres questions?`,
    body: [
      `Vous pouvez nous`,
      `contacter ici`,
      `ou nous appeler au`,
      `1 877 248-7707`,
      ` entre 7 h et 22 h (HNE).`,
    ],
  },
}

const ClaimFulfillmentFooter: FC<{ customer: Contract['customer']; locale?: Locale }> = ({
  customer,
  locale = 'en-US',
}) => {
  const contactUsLink = `/contact?${[
    { key: 'name', value: encodeURI(customer.name?.trim() || '') },
    { key: 'email', value: customer.email },
    { key: 'phoneNumber', value: customer.phone },
  ]
    .filter(({ value }) => Boolean(value))
    .map(({ key, value }) => `${key}=${value}`)
    .join('&')}`

  const { heading, body } = pageText[locale]
  return (
    <footer>
      <ExtendShield src={images.extendIcon} alt="Extend shield" />
      <MainHeading centered>{heading}</MainHeading>
      <ContactInfo data-cy="contact-info">
        {body[0]}&nbsp;
        <Link data-cy="contact-link" to={contactUsLink} target="_blank">
          {body[1]}
        </Link>
        &nbsp;{body[2]}
        <a href="tel:(877) 248-7707">{body[3]}</a>
        {body[4]}
      </ContactInfo>
    </footer>
  )
}

const MainHeading = styled.h1<{ centered?: boolean }>(
  {
    margin: 0,
    fontSize: 26,
    lineHeight: '35px',
    [bp.md]: {
      lineHeight: '45px',
      fontSize: 33,
    },
  },
  ({ centered }) => ({
    textAlign: centered ? 'center' : 'left',
  }),
)

const ContactInfo = styled.p({
  fontSize: 16,
  lineHeight: '27px',
  textAlign: 'center',
  marginBottom: 29,
  '& a:hover': {
    textDecoration: 'underline',
  },
  [bp.md]: {
    margin: '16px 50px',
  },
})

const ExtendShield = styled.img({
  width: 70,
  height: 72,
  margin: '0 auto',
  display: 'block',
  marginTop: 40,
  marginBottom: 32,
  [bp.md]: {
    marginTop: 48,
  },
})

export { ClaimFulfillmentFooter }

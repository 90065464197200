import type { Prompt, Reply, Slot } from '@customers-api-client'
import { ConnectRequest, ConnectResponse } from '@extend-incredibot/types'
import { createActionFailure } from './factories'
import type {
  ChatMessage,
  ChatSessionImageUploadRequest,
  ChatSessionUpdateRequest,
} from '../types/chat-message'

// Connecting the Chat
export const chatSessionConnectRequest = () =>
  ({
    type: 'CHAT_SESSION_CONNECT_REQUEST',
  } as const)

export const chatSessionConnectSuccess = (initialReply: ConnectResponse) =>
  ({
    type: 'CHAT_SESSION_CONNECT_SUCCESS',
    payload: initialReply,
  } as const)

export const chatSessionConnectFailure = createActionFailure('CHAT_SESSION_CONNECT_FAILURE')

export const chatSessionReady = () =>
  ({
    type: 'CHAT_SESSION_READY',
  } as const)

// Restarting the Chat
export const chatSessionRestart = (
  payload?: Omit<ConnectRequest, 'source' | 'filedBy'> & { accessToken: string },
) =>
  ({
    type: 'CHAT_SESSION_RESTART',
    payload,
  } as const)

export const chatMessagesReset = () =>
  ({
    type: 'CHAT_MESSAGES_RESET',
  } as const)

// Updating the Chat
export const chatSessionUpdate = ({ slot, message, value }: ChatSessionUpdateRequest) =>
  ({
    type: 'CHAT_SESSION_UPDATE',
    payload: { message, value, slot },
  } as const)

export const chatSessionUpdateRequest = () => ({ type: 'CHAT_SESSION_UPDATE_REQUEST' } as const)

export const chatSessionUpdateSuccess = (data: Reply) =>
  ({
    type: 'CHAT_SESSION_UPDATE_SUCCESS',
    payload: data,
  } as const)

export const chatSessionUpdateFailure = createActionFailure('CHAT_SESSION_UPDATE_FAILURE')

export const chatSessionImageUpload = ({
  slot,
  file,
  name,
  presignedPost,
  description,
}: ChatSessionImageUploadRequest) =>
  ({
    type: 'CHAT_SESSION_IMAGE_UPLOAD',
    payload: { slot, file, name, presignedPost, description },
  } as const)

export const chatSessionSetLastSlot = (lastPromptSlot: Slot) =>
  ({
    type: 'CHAT_SESSION_SET_LAST_SLOT',
    payload: lastPromptSlot,
  } as const)

// Updating Chat content
export const chatMessagesAdd = (message: ChatMessage) =>
  ({
    type: 'CHAT_MESSAGES_ADD',
    payload: message,
  } as const)

export const chatMessagesUpdateMessage = (updatedMessage: ChatMessage, key: string) =>
  ({
    type: 'CHAT_MESSAGES_UPDATE_MESSAGE',
    payload: {
      updatedMessage,
      key,
    },
  } as const)

export const chatMessagesSet = (messages: ChatMessage[]) =>
  ({
    type: 'CHAT_MESSAGES_SET',
    payload: messages,
  } as const)

export const chatMessagesStopTyping = (key: string) =>
  ({
    type: 'CHAT_MESSAGES_STOP_TYPING',
    payload: key,
  } as const)

export const chatPromptSet = (prompt: Prompt) =>
  ({
    type: 'CHAT_PROMPT_SET',
    payload: prompt,
  } as const)

export const chatPromptClear = () => ({ type: 'CHAT_PROMPT_CLEAR' } as const)

// Multiselect prompt updates
export const chatMultiselectInit = (prompt: Prompt) =>
  ({
    type: 'CHAT_PROMPT_MULTISELECT_INIT',
    payload: prompt,
  } as const)

export const chatMultiselectShow = () =>
  ({
    type: 'CHAT_PROMPT_MULTISELECT_SHOW',
  } as const)

export const chatMultiselectHide = () =>
  ({
    type: 'CHAT_PROMPT_MULTISELECT_HIDE',
  } as const)

// Updating the slots
export const chatSlotsSet = (slots: Slot[]) =>
  ({
    type: 'CHAT_SLOTS_SET',
    payload: slots,
  } as const)

// Chat Intro
export const setChatIntroMoving = () =>
  ({
    type: 'SET_CHAT_INTRO_MOVING',
  } as const)

export const setChatIntroPosition = (position: number) =>
  ({
    type: 'SET_CHAT_INTRO_BOTTOM',
    payload: position,
  } as const)

import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { animated, useTransition } from 'react-spring'
import { useIntl } from 'react-intl'
import { useHistory, useLocation } from 'react-router-dom'
import { PopaFlow } from '../../components/popa-flow'
import { MyExtendAuthForm } from './myextend-auth-form'
import { getItem, removeItem } from '../../store/persistence'
import { useTheme } from '@emotion/react'

export interface UserInfo {
  email: string
  phoneNumber: string
}

const MyExtendAuthentication: FC = () => {
  const ANIMATION_DURATION = 250
  const history = useHistory()
  const [userInfo, setUserInfo] = useState<UserInfo>({ email: '', phoneNumber: '' })
  const [isReset, setIsReset] = useState<boolean>(false)
  const theme = useTheme()

  const { search } = useLocation()
  const rawLoginMethods = new URLSearchParams(search).get('loginMethods')
  const loginMethods = rawLoginMethods ? rawLoginMethods.split(',') : []
  const isRestrictedLogin = loginMethods.length === 1

  /**
   * WARNING: ugly code ahead!
   * The 'position: absolute' of the animation was breaking the page layout.
   * Our hack here is to conditionally inject 'position: absolute' if the animation should be displayed, otherwise inject 'position: relative'.
   * We use this state var to control which position style should be used based on whether the submit button that triggers the animation is clicked.
   */
  const [shouldDisplayFormAnimation, setShouldDisplayFormAnimation] = useState<boolean>(false)

  const intl = useIntl()

  const formTransitionProps = useTransition(!userInfo.email && !userInfo.phoneNumber, null, {
    config: { duration: ANIMATION_DURATION, mass: 5, friction: 120, tension: 120 },
    from: {
      transform: isReset ? 'translateX(50%)' : 'translateX(0%)',
      opacity: 1,
      position: 'absolute',
    },
    leave: { transform: 'translateX(-50%)', opacity: 0 },
    enter: { transform: 'translateX(0%)', opacity: 1 },
    update: { transform: 'translateX(0%)', opacity: 1 },
  })
  const confirmationTransitionProps = useTransition(userInfo.email || userInfo.phoneNumber, null, {
    config: { duration: ANIMATION_DURATION },
    from: { transform: 'translateX(50%)', opacity: 0, position: 'absolute' },
    enter: { transform: 'translateX(0)', opacity: 1 },
    leave: { transform: 'translateX(-50%)', opacity: 0 },
    reset: true,
    update: { transform: 'translateX(0)', opacity: 1 },
  })

  const handleSubmit = async (info: UserInfo): Promise<void> => {
    // ready to animate
    setShouldDisplayFormAnimation(true)

    setUserInfo(info)
    setIsReset(false)

    // A simple toggle false of state var here is too fast because the animation has a duration of 250 ms.
    setTimeout(() => setShouldDisplayFormAnimation(false), ANIMATION_DURATION + 250)
  }

  const handleResetForm = async (): Promise<void> => {
    setUserInfo({ email: '', phoneNumber: '' })
    setIsReset(true)
  }

  const handleSuccess = (): void => {
    const prevLocation = getItem('prevLocation')
    if (prevLocation) {
      history.push(`${prevLocation}`)
      removeItem('prevLocation')
    } else {
      history.push(`/${intl.locale}/my_plans`)
    }
  }

  const form = formTransitionProps.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          // if you're confused, see comment on state var above
          style={{ ...props, position: shouldDisplayFormAnimation ? 'absolute' : 'relative' }}
          data-cy="animation-form"
        >
          <Wrapper
            loginBackgroundImage={
              theme.merchantConfiguration.brandingConfigurations.assetsUrls.loginBackground
            }
          >
            <Container>
              <AuthFormWrapper>
                <MyExtendAuthForm
                  isRestrictedLogin={isRestrictedLogin}
                  isRestrictedEmail={isRestrictedLogin && loginMethods[0] === 'email'}
                  onSubmit={handleSubmit}
                />
              </AuthFormWrapper>
            </Container>
          </Wrapper>
        </animated.div>
      ),
  )
  const confirmation = confirmationTransitionProps.map(
    ({ item, key, props }) =>
      item && (
        <animated.div
          key={key}
          style={{ ...props, position: shouldDisplayFormAnimation ? 'absolute' : 'relative' }}
        >
          <Wrapper
            loginBackgroundImage={
              theme.merchantConfiguration.brandingConfigurations.assetsUrls.loginBackground
            }
          >
            <PopaFlow
              userInfo={userInfo}
              isAuth
              resetForm={handleResetForm}
              handleSuccess={handleSuccess}
            />
          </Wrapper>
        </animated.div>
      ),
  )

  return (
    <>
      {(!userInfo.email || !userInfo.phoneNumber) && form}
      {(userInfo.email || userInfo.phoneNumber) && confirmation}
    </>
  )
}

const AuthFormWrapper = styled.div({
  maxWidth: 488,
})

const Wrapper = styled.div<{ loginBackgroundImage: string }>(({ loginBackgroundImage }) => ({
  display: 'flex',
  flexDirection: 'column',
  width: '100vw',
  justifyContent: 'center',
  alignItems: 'center',
  [bp.mobile]: {
    margin: 0,
    padding: '48px 20px 152px',
    marginTop: 40,
  },
  [bp.desktop]: {
    position: 'relative',
    padding: 0,
    height: '100vh',
    backgroundImage: `url(${loginBackgroundImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center top',
  },
}))

const Container = styled.div<{ isPasscodeInput?: boolean }>(({ isPasscodeInput }) => ({
  display: 'flex',
  alignItems: 'center',
  [bp.mobile]: {
    justifyContent: 'center',
    flexDirection: isPasscodeInput ? 'column' : 'column-reverse',
    padding: 0,
    gap: 50,
    width: '100%',
  },
  [bp.desktop]: {
    width: '100%',
    gap: isPasscodeInput ? 0 : 100,
    padding: 0,
    justifyContent: 'center',
    flexDirection: isPasscodeInput ? 'column' : 'row',
  },
}))

export { MyExtendAuthentication }

import { createApi } from '@reduxjs/toolkit/query/react'
import type { Plan, PlanDetails } from '@customers-api-client'
import { baseQuery } from '../base-query'

export const plansApi = createApi({
  baseQuery,
  reducerPath: 'Plans',
  tagTypes: ['Plans'],
  endpoints: (build) => ({
    fetchPlan: build.query<Plan, string>({
      query: (planId: string) => ({
        url: `/plans/${planId}?cc=${Date.now()}`, // Add the current time stamp to break caching.
      }),
      providesTags: (_, _err, planId) => [{ type: 'Plans', id: planId }],
    }),
    fetchPlanDetails: build.query<PlanDetails, string>({
      query: (planId: string) => ({
        url: `/plans/${planId}/details`,
      }),
      providesTags: (_, _err, planId) => [{ type: 'Plans', id: planId }],
    }),
  }),
})

export const {
  useFetchPlanQuery,
  useFetchPlanDetailsQuery,
  usePrefetch: usePrefetchPlans,
} = plansApi
export const { fetchPlan } = plansApi.endpoints

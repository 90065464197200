import type { FC } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { COLOR, bp, Grid, Card, ViewField } from '@customers-ui'
import { formatTermLength } from '../../../utils/format-term-length'
import { images } from '../../../lib/assets'
import type { ExtendedWarrantyContract } from '../../../types/contract'

export interface ExtendedWarrantyProductDetailsProps {
  contract: ExtendedWarrantyContract
}

const ExtendedWarrantyProductDetails: FC<ExtendedWarrantyProductDetailsProps> = ({ contract }) => {
  const { product, coverage, planDetails, sellerName, id } = contract
  const { name, imageUrl } = product
  const termLength = planDetails ? formatTermLength(planDetails.term_length) : ''
  const startDate = dayjs(coverage?.starts).format('MMMM D, YYYY')
  const endDate =
    planDetails?.term_length === 999 ? 'Lifetime' : dayjs(coverage?.ends).format('MMMM D, YYYY')
  const coverageDates = `${startDate} - ${endDate}`

  return (
    <StyledCard>
      <ContentGrid>
        <ProductImage image={imageUrl} title={name} data-cy="product-image" />
        <ProductDetailsGrid>
          <Title data-cy="title">{name}</Title>
          <StyledViewField title="CONTRACT ID" value={id} dataCy="contract-id" />
          <FieldGroupGrid>
            <StyledViewField title="STORE NAME" value={sellerName} dataCy="store-name" />
            <StyledViewField
              title="TERM"
              value={planDetails?.term_length === 999 ? 'Lifetime' : termLength}
              dataCy="term"
            />
            <CoverageDatesField
              title="COVERAGE DATES"
              value={coverageDates}
              dataCy="coverage-dates"
            />
          </FieldGroupGrid>
        </ProductDetailsGrid>
      </ContentGrid>
    </StyledCard>
  )
}

const StyledCard = styled(Card)({
  width: '100%',
  background: COLOR.BLUE[100],
  border: 'none',
  [bp.mobile]: {
    padding: 16,
  },
  [bp.desktop]: {
    width: 668,
    margin: '0 auto',
    padding: 32,
  },
})

const ContentGrid = styled(Grid)({
  width: '100%',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const ProductDetailsGrid = styled(Grid)({
  flexDirection: 'column',
  width: '100%',
  [bp.mobile]: {
    margin: '16px 0 0 0',
  },
  [bp.desktop]: {
    margin: '0 0 0 24px',
  },
})

const FieldGroupGrid = styled(Grid)({
  width: '100%',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
})

const ProductImage = styled.div<{ image?: string }>(({ image }) => ({
  background: `no-repeat center center`,
  backgroundImage: `url(${image}), url(${images.defaultProductImage})`,
  backgroundSize: 'cover',
  maxHeight: 80,
  maxWidth: 80,
  minHeight: 80,
  minWidth: 80,
  borderRadius: 16,
}))

const Title = styled.div({
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

const StyledViewField = styled(ViewField)({
  marginTop: 24,
})

const CoverageDatesField = styled(StyledViewField)({
  minWidth: 200,
})

export { ExtendedWarrantyProductDetails }

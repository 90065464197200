import type { ApiResponse } from '@customers-api-fetch'
import { post } from '@customers-api-fetch'
import { customLogger } from '@extend/client-helpers'
import { ConnectRequest, ConnectResponse } from '@extend-incredibot/types'
import { getTokenSub } from '@src/lib/security-utils'
import type { Reply, Slot, SlotValue } from '../models/claims-assistant'

export async function connect({
  data: { source, filedBy, contractId, orderId, lineItemIds, shipmentId },
  accessToken,
}: {
  data: ConnectRequest
  accessToken: string
}): Promise<ApiResponse<ConnectResponse>> {
  return post('/claims-assistant/connect', {
    body: {
      source,
      filedBy,
      contractId,
      orderId,
      lineItemIds,
      shipmentId,
      apiVersion: 'latest',
    },
    headers: {
      ['X-Extend-Access-Token']: accessToken,
    },
  })
}

interface UpdateOpts {
  slotValue?: SlotValue
  message?: string
}

export async function update(
  slot: Slot,
  accessToken: string,
  opts: UpdateOpts = {},
): Promise<ApiResponse<Reply>> {
  const sessionId = getTokenSub(accessToken)

  const { slotValue, message } = opts

  customLogger.info(`Calling /update, sessionId: ${sessionId}`, {
    sessionId,
    slot,
    slotValue,
    timestamp: Date.now(),
  })

  return post('/claims-assistant/update', {
    accessToken,
    body: {
      slot,
      slotValue,
      message,
    },
  })
}

import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'

import { FormattedMessage } from '@customers-ui'
import { images } from '../../lib/assets'

const ContactFormSuccess: FC = () => {
  return (
    <Wrapper data-cy="contact-form-success">
      <Emoji src={images.raisedHandsEmoji} aria-label="raised hands" alt="raised hands" />
      <Header>
        <FormattedMessage
          id="SUPPORT_FORM_SUCCESS_TITLE"
          defaultMessage="Thanks for reaching out!"
          description="This title expresses gratitude for submitting the support form"
        />
      </Header>
      <Content>
        <FormattedMessage
          id="SUPPORT_FORM_SUCCESS_MESSAGE"
          defaultMessage="We will be in touch soon."
        />
      </Content>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  display: 'flex',
  height: '80vh',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
})

const Emoji = styled.img({
  width: 50,
  height: 'auto',
})

const Header = styled.h3({
  fontSize: 22,
  fontWeight: 800,
})

const Content = styled.p({
  fontSize: 16,
  fontWeight: 700,
})

export { ContactFormSuccess }

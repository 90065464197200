import type { PlanTermsGetResponse, PlanTermsSetResponse } from '@customers-api-client'
import { createApi } from '@reduxjs/toolkit/query/react'
import type { PlanTermsGetUrlsResponse } from '@extend-plans/types'
import { baseQuery } from '../base-query'
import type {
  PlanTermsDocumentGetRequest,
  PlanTermsVersionLanguageUrlRequest,
  PlanTermsSetRequest,
} from './types'

export const plansTermsApi = createApi({
  baseQuery,
  reducerPath: 'Terms',
  tagTypes: ['Terms', 'TermsId', 'TermsVersion'],
  endpoints: (build) => ({
    getPlanTermsVersionLanguageUrl: build.query<
      PlanTermsGetResponse,
      PlanTermsVersionLanguageUrlRequest
    >({
      query: ({ termsId, version, language }) => ({
        url: `/plans/terms/${termsId}/versions/${version}/languages/${language}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsSet: build.query<PlanTermsSetResponse, PlanTermsSetRequest>({
      query: ({ termsId }) => ({
        url: `/plans/terms-set/links/${termsId}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
    getPlanTermsDocuments: build.query<PlanTermsGetUrlsResponse, PlanTermsDocumentGetRequest>({
      query: ({ termsId, version }) => ({
        url: `/plans/terms/${termsId}/documents${version ? `?termsVersion=${version}` : ''}`,
        headers: {
          'content-type': 'application/json',
          accept: 'application/json; version=latest;',
        },
      }),
    }),
  }),
})

export const {
  usePrefetch: usePrefetchPlanTerms,
  useLazyGetPlanTermsVersionLanguageUrlQuery,
  useLazyGetPlanTermsSetQuery,
  useGetPlanTermsDocumentsQuery,
} = plansTermsApi

import type { FC, SyntheticEvent } from 'react'
import React from 'react'
import { useLocation } from 'react-router'
import { useSelector } from 'react-redux'
import { useFormik } from 'formik'
import styled from '@emotion/styled'
import { Input, InputType, TextArea, AdvancedSelect, Button } from '@extend/zen'
import {
  querystring,
  formatPhoneNumberIso,
  formatPhoneNumberLocal,
  formatPhoneNumberOnChange,
} from '@extend/client-helpers'
import { bp } from '@customers-ui'
import type { ContactRequest } from '@customers-api-rtk-query'
import { useIntl } from 'react-intl'
import * as selectors from '../../reducers/selectors'
import { ContactFormHeader } from './contact-form-header'
import { useContactFormSchema } from './contact-form.utils'

export interface ContactFormProps {
  onSubmit: (formValues: ContactRequest) => void
  isLoading: boolean
  isError: boolean
}

type QueryStringProps = {
  name: string
  email: string
  phoneNumber: string
}

const ContactForm: FC<ContactFormProps> = ({ onSubmit, isLoading }) => {
  const intl = useIntl()
  const { search } = useLocation()
  const params = querystring.parse<QueryStringProps>(search)
  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)
  const email = decodedAccessToken?.email || ''
  const contactFormSchema = useContactFormSchema()

  const dropdownPlaceholder = {
    value: 'selectOption',
    display: intl.formatMessage({
      id: 'SUPPORT_FORM_SUPPORT_TOPIC_SELECT_OPTION',
      defaultMessage: 'Select an option',
      description: 'Instructions to select an option from the dropdown',
    }),
  }

  const dropdownOptions = [
    {
      value: 'productProtection',
      display: intl.formatMessage({
        id: 'SUPPORT_FORM_SUPPORT_TOPIC_PRODUCT_PROTECTION',
        defaultMessage: 'Product Protection',
        description: 'Support topic option for product protection',
      }),
    },

    {
      value: 'shippingProtection',
      display: intl.formatMessage({
        id: 'SUPPORT_FORM_SUPPORT_TOPIC_SHIPPING_PROTECTION',
        defaultMessage: 'Shipping Protection',
        description: 'Support topic option for shipping protection',
      }),
    },

    {
      value: 'other',
      display: intl.formatMessage({
        id: 'SUPPORT_FORM_SUPPORT_TOPIC_OTHER',
        defaultMessage: 'Other',
        description: 'Support topic option for something other than product or shipping protection',
      }),
    },
  ]

  const {
    errors,
    values,
    isSubmitting,
    handleChange,
    handleBlur,
    handleSubmit,
    setFieldError,
    isValid,
  } = useFormik({
    enableReinitialize: true,
    validationSchema: useContactFormSchema(),
    validateOnChange: true,
    validateOnBlur: true,
    validateOnMount: true,
    initialValues: {
      name: decodeURI(params.name || ''),
      email: decodeURI(params.email || `${email}`),
      phoneNumber: formatPhoneNumberLocal(decodeURI(params.phoneNumber || ''), 'US'),
      requestType: '' as ContactRequest['requestType'],
      message: '',
      locale: intl.locale,
    },
    onSubmit: (formValues: ContactRequest): void => {
      const castValues = contactFormSchema.cast(formValues) as ContactRequest
      castValues.phoneNumber = formatPhoneNumberIso(castValues.phoneNumber as string, 'US')
      onSubmit(castValues)
    },
  })

  const handleOnChange = (e: SyntheticEvent<HTMLInputElement>): void => {
    const field = e.target as HTMLInputElement
    if (field.id === 'phoneNumber') {
      field.value = formatPhoneNumberOnChange(field.value)
    }
    setFieldError(field.id, undefined)
    handleChange(e)
  }

  const hasAllValues = isValid && Object.values(values).every((value) => !!value)

  return (
    <Wrapper data-cy="contact-form-wrapper">
      <ContactFormHeader />
      <StyledForm data-qa="customers--contact-us-form" onSubmit={handleSubmit}>
        <Input
          id="name"
          label={intl.formatMessage({
            id: 'SUPPORT_FORM_NAME_LABEL',
            defaultMessage: 'Name',
            description: "Prompt for the user's name",
          })}
          type={InputType.text}
          value={values.name}
          isDisabled={isSubmitting}
          isError={!values.name || !!errors.name}
          errorFeedback={errors.name}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy="contact-form-name"
        />
        <Input
          id="email"
          label={intl.formatMessage({
            id: 'SUPPORT_FORM_EMAIL_LABEL',
            defaultMessage: 'Email',
            description: "Prompt for the user's email address",
          })}
          type={InputType.text}
          value={values.email}
          isError={!values.email || !!errors.email}
          isDisabled={isSubmitting}
          errorFeedback={errors.email}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy="contact-form-email"
        />
        <Input
          id="phoneNumber"
          label={intl.formatMessage({
            id: 'SUPPORT_FORM_PHONE_NUMBER_LABEL',
            defaultMessage: 'Phone Number',
            description: "Prompt for the user's phone number",
          })}
          type={InputType.tel}
          value={values.phoneNumber || ''}
          isError={!values.phoneNumber || !!errors.phoneNumber}
          isDisabled={isSubmitting}
          errorFeedback={errors.phoneNumber}
          onChange={handleOnChange}
          onBlur={handleBlur}
          data-cy="contact-form-phone-number"
        />
        <AdvancedSelect
          id="requestType"
          label={intl.formatMessage({
            id: 'SUPPORT_FORM_SUPPORT_TOPIC_LABEL',
            defaultMessage: 'Support Topic',
            description: 'Prompt for the support topic in the following dropdown',
          })}
          isError={!values.requestType || !!errors.requestType}
          isDisabled={isSubmitting}
          errorFeedback={errors.requestType}
          options={dropdownOptions}
          onChange={handleChange}
          value={values.requestType}
          multiple={false}
          placeholder={dropdownPlaceholder.display}
          data-cy="contact-form-support-topic"
        />
        <TextArea
          id="message"
          label={intl.formatMessage({
            id: 'SUPPORT_FORM_MESSAGE_LABEL',
            defaultMessage: 'How can we help you?',
            description: "Prompt for user's message to support",
          })}
          value={values.message}
          isError={!values.message || !!errors.message}
          isDisabled={isSubmitting}
          errorFeedback={errors.message}
          onChange={handleChange}
          onBlur={handleBlur}
          data-cy="contact-form-message"
        />
        <ButtonWrapper>
          <Button
            text={intl.formatMessage({
              id: 'SUPPORT_FORM_SUBMIT_BUTTON',
              defaultMessage: 'Send Message',
              description: 'Button label to send message to support',
            })}
            isProcessing={isSubmitting || isLoading}
            isDisabled={!hasAllValues}
            type="submit"
            fillContainer
            data-cy="contact-form-submit"
          />
        </ButtonWrapper>
      </StyledForm>
    </Wrapper>
  )
}

const Wrapper = styled.div({
  [bp.mobile]: {
    width: '100%',
    margin: 0,
    padding: '26px 20px 60px',
  },
  [bp.desktop]: {
    width: 400,
    margin: '40px auto 80px',
    padding: 0,
  },
})

const StyledForm = styled.form({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
  justifyContent: 'center',
})

const ButtonWrapper = styled.div({
  display: 'flex',
  justifyContent: 'center',
})

export { ContactForm }

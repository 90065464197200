import { Claim, PhotoRequirement, ClaimPhotoDetail } from '../types/claim'

export interface CurrentRequirementState {
  isLastRequirement: boolean
  requirement: PhotoRequirement
  index: number
}

export const getInitialPhotoRequirementsState = (claim: Claim, claimPhotos: ClaimPhotoDetail[]) => {
  if (!claim?.photoRequirements) return null
  // if there are no claim photos, we can assume that we need to show the first requirement
  if (!claimPhotos.length)
    return {
      isLastRequirement: claim.photoRequirements.length === 1,
      requirement: claim.photoRequirements[0],
      index: 0,
    }

  const uploadedPhotos = claimPhotos.filter(
    (photo) => !['deleted', 'rejected'].includes(photo.status),
  )
  const currentRequirementIndex = claim.photoRequirements.findIndex(
    (requirement) => !uploadedPhotos.some((photo) => photo?.requirement?.id === requirement.id),
  )

  if (currentRequirementIndex === -1) return null

  return {
    isLastRequirement: currentRequirementIndex === claim.photoRequirements.length - 1,
    requirement: claim.photoRequirements[currentRequirementIndex],
    index: currentRequirementIndex,
  }
}

export const getNextPhotoRequirement = (
  photoRequirements: PhotoRequirement[],
  currentRequirement: CurrentRequirementState,
  claimPhotos: ClaimPhotoDetail[],
) => {
  for (let i = currentRequirement.index + 1; i < photoRequirements.length; i++) {
    const requirement = photoRequirements[i]

    const isPhotoUploadedAgainstRequirement = claimPhotos.some(
      (photo) =>
        photo?.requirement?.id === requirement.id &&
        !['deleted', 'rejected'].includes(photo.status),
    )

    if (!isPhotoUploadedAgainstRequirement) {
      return {
        isLastRequirement: i === photoRequirements.length - 1,
        requirement,
        index: i,
      }
    }
  }

  // currentRequirement.index + 1 is longer than the length of the requirements, so there are no more requirements
  return null
}

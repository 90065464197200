import React from 'react'

const Cart: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="64" height="65" viewBox="0 0 64 65">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1"
        transform="translate(-224 -2436) translate(226 2438)"
      >
        <path
          stroke="#224BC5"
          strokeWidth="2.777"
          d="M42.24 55.394a5.264 5.264 0 015.261-5.262 5.264 5.264 0 015.262 5.262 5.264 5.264 0 01-5.262 5.261 5.264 5.264 0 01-5.262-5.261zM15.931 55.394a5.264 5.264 0 015.262-5.262 5.264 5.264 0 015.261 5.262 5.264 5.264 0 01-5.261 5.261 5.264 5.264 0 01-5.262-5.261zM.146 5.408H8.04l6.624 33.117A7.888 7.888 0 0022.4 44.87h30.363"
        />
        <path
          stroke="#224BC5"
          strokeWidth="2.777"
          d="M55.97 29.738a5.262 5.262 0 01-5.222 4.609H14.616"
        />
        <path stroke="#224BC5" strokeWidth="2.777" d="M10.669 13.3L29.085 13.3" />
        <path
          stroke="#27AEE4"
          strokeWidth="2.5"
          d="M34.347 13.3C34.347 6.037 40.237.146 47.5.146S60.655 6.036 60.655 13.3s-5.89 13.154-13.154 13.154-13.154-5.89-13.154-13.154z"
        />
        <path
          stroke="#27AEE4"
          strokeWidth="2.5"
          d="M39.6085938 13.3003027L44.870252 18.5619609 55.3935684 8.03864449"
        />
      </g>
    </svg>
  )
}

export default Cart

import React from 'react'
import type { FC } from 'react'
import { bp } from '@customers-ui'
import styled from '@emotion/styled'
import { isMobile } from 'react-device-detect'
import { COLOR, Stack } from '@extend/zen'
import { images } from '../../../../lib/assets'
import { useScreenSize } from '../../../../hooks'

interface ContentHeaderProps {
  bannerHeader?: string
  bannerBody?: string
  shippingOffersBannerImage?: string
}

const ContentHeader: FC<ContentHeaderProps> = ({
  bannerBody,
  bannerHeader,
  shippingOffersBannerImage,
}) => {
  const screenSize = useScreenSize()
  const spacing = 2
  const heading = bannerHeader ?? 'Extend Shipping Protection'
  const body =
    bannerBody ??
    'Give yourself added peace of mind when online shopping. With Shipping Protection by Extend™, your packages are protected from loss, theft, and damage until they arrive safely in your hands.'
  const image = (shippingOffersBannerImage || images.shippingProtectionMobile) ?? images.charity
  const altImage = 'Shipping Protection'

  if (isMobile || screenSize == 'medium' || screenSize == 'small') {
    return (
      <Stack align="center" spacing={spacing}>
        <MainTitle data-cy="main-title">{heading}</MainTitle>
        <Description>{body}</Description>
        <Img src={image} alt={altImage} />
      </Stack>
    )
  } else {
    return (
      <Stack isRow align="center" spacing={spacing}>
        <Stack spacing={spacing}>
          <MainTitle data-cy="main-title">{heading}</MainTitle>
          <Description>{body}</Description>
        </Stack>
        <Img src={image} alt={altImage} />
      </Stack>
    )
  }
}

const MainTitle = styled.div({
  [bp.mobile]: {
    fontSize: 28,
    lineHeight: '38px',
    fontWeight: 800,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 52,
    lineHeight: '57px',
    fontWeight: 600,
    paddingBottom: 16,
    width: '100%',
    textAlign: 'left',
  },
})

const Description = styled.div({
  fontWeight: 400,
  lineHeight: '30px',

  [bp.mobile]: {
    fontSize: 18,
    width: 345,
    textAlign: 'center',
  },
  [bp.desktop]: {
    fontSize: 20,
    width: '100%',
    textAlign: 'left',
  },
  color: COLOR.NEUTRAL[700],
})

const Img = styled.img({
  maxWidth: '400px',
  maxHeight: '600px',
  borderRadius: '24px',
  transform: 'scale',
})

export { ContentHeader }

import type { FC } from 'react'
import React from 'react'
import { Link } from 'react-router-dom'
import styled from '@emotion/styled'
import { EXTEND_CUSTOMERS_HOST } from '@helloextend/client-constants'
import { COLOR } from '@customers-ui'
import { useTrackingContext } from '../../../lib/client-analytics'

interface ChatLinkedContentProps {
  href: string
  isExternal: boolean
  text: string
  downloadTitle?: string
}

const ChatLinkedContent: FC<ChatLinkedContentProps> = ({
  href,
  isExternal,
  text,
  downloadTitle,
}) => {
  const { trackEvent } = useTrackingContext()

  const handleClick = (): void => {
    trackEvent(`clicked on chat message link`, { href, text })
  }

  return (
    <LinkWrapper style={{ wordBreak: 'break-word' }}>
      {isExternal ? (
        <a
          target="_blank"
          href={href}
          rel="noopener noreferrer"
          download={downloadTitle}
          onClick={handleClick}
          data-cy="chat-linked-content-external"
        >
          {text}
        </a>
      ) : (
        <Link
          to={href}
          target="_blank"
          rel="noopener noreferrer"
          onClick={handleClick}
          data-cy="chat-linked-content-link"
        >
          {text || `https://${EXTEND_CUSTOMERS_HOST}/${href}`}
        </Link>
      )}
    </LinkWrapper>
  )
}

const LinkWrapper = styled.span({
  wordBreak: 'break-word',
  color: COLOR.BLUE[800],
  a: {
    textDecoration: 'none',
    '&:visited': {
      color: COLOR.BLUE[800],
    },
    '&:hover': {
      cursor: 'pointer',
    },
  },
})

export { ChatLinkedContent }

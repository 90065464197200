import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type { ClaimStatus, ClaimCardTrackingActivity } from '@customers-api-client'
import { COLOR } from '@customers-ui'

import { colorScheme } from './color-scheme'

export interface ClaimTrackingActivityProps {
  claimTrackingActivity: ClaimCardTrackingActivity[]
  status: ClaimStatus
}

export const ClaimTrackingActivity: FC<ClaimTrackingActivityProps> = ({
  claimTrackingActivity,
  status,
}) => {
  const activities = claimTrackingActivity.map(
    (claimActivity: ClaimCardTrackingActivity, index: number) => {
      const { message } = claimActivity
      const statusColor = colorScheme[status]

      const isLastIndex = claimTrackingActivity.length === index + 1
      const color =
        !isLastIndex || status === 'denied' ? statusColor.secondary : statusColor.primary

      return (
        <Wrapper key={message}>
          <Icons>
            <Circle color={color} />
            {!isLastIndex && <Line />}
          </Icons>
          <Status>
            <Message>{message}</Message>
          </Status>
        </Wrapper>
      )
    },
  )
  return <>{activities}</>
}

const Icons = styled.div({
  marginRight: '16px',
  marginTop: '3px',
  marginBottom: '3px',
})

const Wrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
})

interface CircleProps {
  color: string
}

const Circle = styled.div<CircleProps>(
  {
    height: 10,
    width: 10,
    borderRadius: '50%',
  },
  (props) => ({
    background: props.color,
  }),
)

const Line = styled.div({
  width: 1,
  background: COLOR.NEUTRAL[300],
  margin: '4px',
  height: '50%',
})

const Status = styled.div({
  '*': {
    marginBottom: 2,
  },
  marginBottom: 8,
})

const Message = styled.div({
  fontSize: 13,
})

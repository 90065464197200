import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import type {
  ServiceOrder,
  ProductReplacementFulfillmentMetaData,
  CreditFulfillmentMetaData,
  ContractsV2GetResponse,
} from '@customers-api-client'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'

export interface ClaimCompleteDetailsProps {
  serviceOrder?: ServiceOrder[]
  isRepair: boolean
  contract?: ContractsV2GetResponse
}

export const ClaimCompleteDetails: FC<ClaimCompleteDetailsProps> = ({
  serviceOrder,
  isRepair,
  contract,
}) => {
  const handleClickTracking = (): void => {
    if (serviceOrder?.length) {
      const fullfillment = serviceOrder[0]
        .fulfillmentMetaData as ProductReplacementFulfillmentMetaData
      const { trackingNumber } = fullfillment
      const trackingUrl = `https://www.fedex.com/fedextrack/?tracknumbers=${trackingNumber}`
      window.open(trackingUrl, '_blank')?.focus()
    }
  }

  const handleClickRedemption = (): void => {
    if (serviceOrder?.length) {
      const fullfillment = serviceOrder[0].fulfillmentMetaData as CreditFulfillmentMetaData
      const { redemptionUrl } = fullfillment
      window.open(redemptionUrl, '_blank')?.focus()
    }
  }

  const isClosed = serviceOrder?.[0]?.status === 'closed'
  if (isClosed) {
    return (
      <DetailsWrapper>
        We’ve determined that your product no longer requires service. Thank you for being an Extend
        customer.
        <br />
        <br />
        If you have another issue with your product you can file another claim.
      </DetailsWrapper>
    )
  }

  if (!!serviceOrder && !isRepair) {
    const fullfillment = serviceOrder[0].fulfillmentMetaData
    if (!fullfillment) {
      return null
    }
    if (fullfillment) {
      if ('trackingNumber' in fullfillment) {
        return (
          <DetailsWrapper>
            Your replacement has been issued in the form of a direct product replacement. You can
            track its progress to you.
            <Button onClick={handleClickTracking}>Track my replacement</Button>
          </DetailsWrapper>
        )
      }
      if ('redemptionUrl' in fullfillment) {
        return (
          <DetailsWrapper>
            Your replacement has been issued in the form of store credit. You can use this redeem at
            {` ${contract?.sellerName}`} by clicking the button below!
            <Button onClick={handleClickRedemption}>Redeem at {`${contract?.sellerName}`}</Button>
          </DetailsWrapper>
        )
      }
    }
  }

  return (
    <DetailsWrapper>
      Your repair claim is complete! Thank you for being an Extend customer.
    </DetailsWrapper>
  )
}

const Button = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: COLOR.BLUE[800],
  borderRadius: 24,
  width: 211,
  height: 38,
  [bp.lg]: {
    width: 300,
    height: 47,
    fontSize: 20,
    lineHeight: '27px',
  },
  color: COLOR.WHITE,
  fontSize: 16,
  lineHeight: '22px',
  fontWeight: 'bold',
  margin: '10px 0 10px',
})

const DetailsWrapper = styled.div({
  [bp.lg]: {
    width: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.md]: {
    width: 400,
    marginTop: 10,
  },
  fontSize: 15,
  lineHeight: '20px',
  width: 307,
  height: '100%',
})

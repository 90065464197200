import type { SagaIterator } from 'redux-saga'
import { all, call, delay, put, select } from '@redux-saga/core/effects'
import { client } from '@customers-api-client'
import FileType from 'file-type/browser'
import { isErrorResponse, isExceptionResponse } from '@customers-api-fetch'
import { customLogger } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { chatActions } from '../../actions'
import { formatUserMessage, generateBotMessage } from '../../lib/chat-utils'
import cancelableLoadBotContent from './cancelable-load-bot-content'
import { setImageUploadFailureMessage } from './set-image-upload-failure-message'

type Action = ReturnType<typeof chatActions.chatSessionImageUpload>

export const FILE_SIZE_ERROR_MESSAGE =
  'Your file size was too large. I only accept file sizes less than 10 MB. Please try again 😥'
export const TEN_MB_BYTES = 10_000_000
export const IMAGE_FILETYPE_ERROR_MESSAGE =
  'Please upload a photo with a PNG, JPG, or GIF file type.'

export function* chatSessionImageUpload(action: Action): SagaIterator {
  const { slot, name, file, presignedPost, description } = action.payload
  const fileType = yield call(FileType.fromBlob, file)
  const { ext } = fileType

  yield put(chatActions.chatSessionUpdateRequest())
  const accessToken = yield select(selectors.getChatAccessToken)
  const imageUrl = URL.createObjectURL(file)
  yield put(
    chatActions.chatMessagesAdd(formatUserMessage({ content: description, slot, imageUrl })),
  )
  // if file size is over 10MB, sets incorrect file size error message
  if (file.size > TEN_MB_BYTES) {
    const failureMessage = {
      ...generateBotMessage(),
      content: FILE_SIZE_ERROR_MESSAGE,
      isTyping: false,
    }
    yield put(chatActions.chatMessagesAdd(failureMessage))
    yield put(chatActions.chatMultiselectShow())
    return
  }
  // if file's image type isn\'t accepted, set file type error message
  const supportedFileExtensions = ['png', 'gif', 'jpg']
  if (!supportedFileExtensions.includes(ext)) {
    const failureMessage = {
      ...generateBotMessage(),
      content: IMAGE_FILETYPE_ERROR_MESSAGE,
      isTyping: false,
    }
    yield put(chatActions.chatMessagesAdd(failureMessage))
    yield put(chatActions.chatMultiselectShow())
    return
  }
  // Load a `dummy` bot message to fill in with the next set of chat-messages
  // after the API requests are completed
  yield delay(500)
  const uploadResponsePlaceholder = generateBotMessage()
  yield put(chatActions.chatMessagesAdd(uploadResponsePlaceholder))
  yield delay(1500)
  try {
    // upload the file in the presigned URL, using the file and presigned post-fields
    const formData = new FormData()
    Object.entries<string | File>(presignedPost.fields).forEach(([key, value]) => {
      formData.append(key, value)
    })
    // actual file has to be appended last
    formData.append('file', file)
    const [presignedUploadResponse, sessionResponse] = yield all([
      call(client.claimsPhotos.uploadToS3, presignedPost.url, formData),
      call(client.incredibot.update, slot, accessToken, {
        slotValue: name,
      }),
    ])
    // Error response from the presigned S3 URL, where the user gets a canned message,
    // and the option to get redirected to the contact page
    if (isErrorResponse(presignedUploadResponse)) {
      customLogger.error(`Failed to upload an image in the chat-session`, {
        timestamp: Date.now(),
        statusCode: presignedUploadResponse.status,
      })
      yield call(setImageUploadFailureMessage, uploadResponsePlaceholder.key)
      return
    }
    // Error response from POST: claims-assistant/update
    if (isErrorResponse(sessionResponse)) {
      yield put(
        chatActions.chatSessionUpdateFailure(sessionResponse.data.message, sessionResponse.status),
      )
      return
    }
    if (isExceptionResponse(sessionResponse)) {
      yield put(
        chatActions.chatSessionUpdateFailure('An unknown error occurred', sessionResponse.status),
      )
      return
    }
    yield put(chatActions.chatSessionUpdateSuccess(sessionResponse.data))
    yield call(cancelableLoadBotContent, sessionResponse.data, slot, uploadResponsePlaceholder.key)
  } catch (e) {
    if (e instanceof Error) {
      customLogger.error(`Error occurred during a claims-photo upload session`, {
        timestamp: Date.now(),
        errorMessage: e.message,
      })
    }
    yield call(setImageUploadFailureMessage, uploadResponsePlaceholder.key)
  }
}

import React from 'react'
import styled from '@emotion/styled'
import type { ServiceOrder } from '@customers-api-client'
import { COLOR, Icon, SentimentCalm, Timeline, TimelineItem } from '@extend/zen'
import type { FC } from 'react'
import { bp } from '@customers-ui'
import { AUTOMATED_REPLACEMENT_LOOKOUT_TEXT } from './contstants'

export interface AutomatedReplacementApprovedTimelineProps {
  serviceOrder: ServiceOrder
}

export const AutomatedReplacementApprovedTimeline: FC<
  AutomatedReplacementApprovedTimelineProps
> = ({ serviceOrder }) => {
  const isReplacementOrderCreated = [
    'fulfilled',
    'payment_requested',
    'payment_approved',
    'paid',
  ].includes(serviceOrder.status)

  const getOrderCreatedLabel = (): JSX.Element => {
    const text = 'Replacement order created'
    if (isReplacementOrderCreated) {
      return <strong>{text}</strong>
    }

    return <span>{text}</span>
  }

  return (
    <TimelineWrapper data-cy="automated-replacement-timeline">
      <Timeline>
        <TimelineItem
          label="Claim approved"
          state="complete"
          data-cy="claim-approved-timeline-item"
        />
        <TimelineItem
          label="Waiting for replacement order to be created"
          state={isReplacementOrderCreated ? 'complete' : 'current'}
          data-cy="order-replacement-timeline-item"
        >
          {!isReplacementOrderCreated && (
            <TimelineContent>
              <Icon
                icon={SentimentCalm}
                color={`var(--neutral-700, ${COLOR.NEUTRAL[700]});`}
                data-cy="sentiment-calm-icon"
              />
              <div data-cy="no-action-needed-text">Relax, no action needed.</div>
            </TimelineContent>
          )}
        </TimelineItem>
        <TimelineItem
          label={getOrderCreatedLabel()}
          state={isReplacementOrderCreated ? 'success' : 'upcoming'}
          data-cy="claim-fulfilled-timeline-item"
        >
          {isReplacementOrderCreated && (
            <TimelineContent>
              <Icon
                icon={SentimentCalm}
                color={`var(--neutral-700, ${COLOR.NEUTRAL[700]});`}
                data-cy="sentiment-calm-icon"
              />
              <div data-cy="replacement-lookout-text">{AUTOMATED_REPLACEMENT_LOOKOUT_TEXT}</div>
            </TimelineContent>
          )}
        </TimelineItem>
      </Timeline>
    </TimelineWrapper>
  )
}

const TimelineWrapper = styled.div({
  width: '100%',
})

const TimelineContent = styled.div({
  color: COLOR.NEUTRAL[800],
  fontWeight: 700,
  width: '100%',
  display: 'flex',
  gap: '4px',
  flexDirection: 'column',
  [bp.mobile]: {
    flexDirection: 'row',
    alignItems: 'center',
  },
  [bp.desktop]: {
    alignItems: 'flex-start',
    flexDirection: 'row',
  },
})

import type { FC } from 'react'
import React, { useState } from 'react'
import styled from '@emotion/styled'
import { ImagePreviewModal } from './image-preview-modal'

const MISSING_IMG_URL = 'https://orders.bondiproduce.com/producepro/images/missing.png'

interface ImagePreviewProps {
  title?: string
  imageUrl?: string
}

const Component: FC<ImagePreviewProps> = ({ imageUrl, title }) => {
  const [showPreview, setShowPreview] = useState(false)

  const handleClickImage = (): void => {
    if (imageUrl) setShowPreview(true)
  }

  const handleHideModal = (): void => {
    setShowPreview(false)
  }

  return (
    <>
      <ImageTag
        onClick={handleClickImage}
        src={imageUrl || MISSING_IMG_URL}
        hasImage={Boolean(imageUrl)}
        alt="img"
      />
      {imageUrl && showPreview && (
        <ImagePreviewModal
          image={imageUrl}
          onHide={handleHideModal}
          title={title}
          show={showPreview}
        />
      )}
    </>
  )
}

const ImageTag = styled.img<{ hasImage: boolean }>(({ hasImage }) => ({
  cursor: hasImage ? 'pointer' : 'not-allowed',
  maxHeight: 30,
  userSelect: 'none',
}))

const ImagePreview = React.memo(Component)

export { Component, ImagePreview, ImageTag, MISSING_IMG_URL }

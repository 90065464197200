import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import { LinkButton, Timeline, TimelineItem } from '@extend/zen'
import type { Claim } from '@customers-api-client'
import { formatDate } from '../../../lib/helper-functions'

export interface ClaimDeniedProps {
  claim: Claim
}

export const ClaimDenied: FC<ClaimDeniedProps> = ({ claim }) => {
  const date = claim?.deniedAt

  return (
    <ClaimCardContainer data-cy="claim-denied-timeline">
      <Timeline>
        <TimelineItem
          label={
            <>
              <TimelineBold>Claim denied</TimelineBold>
              {date ? `: ${formatDate(date)}` : ''}
            </>
          }
          data-cy="claim-denied"
        />
      </Timeline>
      <TimelineFooter>
        <div>
          <LinkButton
            data-cy="get-help-button"
            emphasis="medium"
            text="Get help"
            color="neutral"
            to="/contact"
          />
        </div>
      </TimelineFooter>
    </ClaimCardContainer>
  )
}

const ClaimCardContainer = styled.div({
  width: '100%',
})

const TimelineBold = styled.span({
  fontWeight: 700,
})

const TimelineFooter = styled.div({
  width: '100%',
  display: 'flex',
  [bp.mobile]: {
    flexDirection: 'row',
    justifyContent: 'center',
  },
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
  },
})

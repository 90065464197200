import type { FC } from 'react'
import React from 'react'
import dayjs from 'dayjs'
import styled from '@emotion/styled'
import { bp, ViewField } from '@customers-ui'
import { currency } from '@extend/client-helpers'
import { formatTermLength } from '../../utils/format-term-length'
import type {
  CategoryContract,
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
} from '../../types/contract'

export interface PlanDetailsFieldsProps {
  contract: ContractsSearchIndividual
  isADH: boolean
}

const PlanDetailsFields: FC<PlanDetailsFieldsProps> = ({ contract, isADH }) => {
  const { planDetails, coverage } = contract as ExtendedWarrantyContract | CategoryContract
  const starts = coverage?.starts ?? 0
  const ends = coverage?.ends ?? 0
  const termLength = planDetails ? formatTermLength(planDetails.term_length) : ''
  const startDate = dayjs(starts).format('MMMM D, YYYY')
  const endDate = planDetails?.term_length === 999 ? 'Lifetime' : dayjs(ends).format('MMMM D, YYYY')
  const coverageDates = `${startDate} - ${endDate}`

  return (
    <PlanDetailsWrapper data-cy="plan-details-wrapper">
      <Row>
        <StyledViewField dataCy="contractId" title="CONTRACT ID" value={contract.id} />
        <StyledViewField
          dataCy="planPrice"
          title="PLAN PRICE"
          value={currency.format(contract.purchasePrice)}
        />
      </Row>
      <Row>
        <StyledViewField
          dataCy="termLength"
          title="CONTRACT TERM"
          value={planDetails?.term_length === 999 ? 'Lifetime' : termLength}
        />
        <StyledViewField dataCy="coverageDates" title="COVERAGE TERM" value={coverageDates} />
      </Row>
      <Row>
        <StyledViewField
          dataCy="deductible"
          title="DEDUCTIBLE"
          value={`$${planDetails?.deductible}`}
        />
        <StyledViewField
          dataCy="adh-coverage"
          title="COVERAGE TYPE"
          value={`Extend Protection${isADH ? ' with ADH' : ''}`}
        />
      </Row>
    </PlanDetailsWrapper>
  )
}

const PlanDetailsWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  marginTop: 8,
})

const Row = styled.div({
  [bp.desktop]: {
    display: 'flex',
    flexDirection: 'row',
  },
})

const StyledViewField = styled(ViewField)({
  marginTop: 24,
})

export { PlanDetailsFields }

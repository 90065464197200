import React from 'react'
import type { ChangeEvent, FC } from 'react'
import styled from '@emotion/styled'
import type { ValidImageExtension } from '@extend/zen'
import { COLOR, ImageInput, isFileValidImage, isFileValidSize } from '@extend/zen'
import type { PhotoRequirement } from '../../types/claim'

interface PhotoInstructionProps {
  photoRequirement: PhotoRequirement
  index: number
  onChange?: (photo: File, index: number) => void
  isUploadingPhotos?: boolean
  isPhotoRejected?: boolean
}

const MAX_FILE_SIZE_MB = 5
const SUPPORTED_FILE_EXTENSIONS: ValidImageExtension[] = ['jpeg', 'jpg', 'png']

const PhotoInstruction: FC<PhotoInstructionProps> = ({
  photoRequirement,
  index,
  isUploadingPhotos = false,
  isPhotoRejected = false,
  onChange,
}) => {
  const handleImageChange = async (event: ChangeEvent<HTMLInputElement>): Promise<void> => {
    if (!onChange) return
    const imageFile = event.target.files && event.target.files[0]

    if (!imageFile) {
      return
    }

    const isValidFile =
      isFileValidImage(imageFile, SUPPORTED_FILE_EXTENSIONS) &&
      isFileValidSize(imageFile, MAX_FILE_SIZE_MB)

    if (!isValidFile) {
      return
    }

    onChange(imageFile, index)
  }

  return (
    <InstructionWrapper data-cy="photo-instruction-wrapper">
      <InstructionIndex data-cy="photo-instruction-index">({index + 1})</InstructionIndex>
      <ImageAndInstruction>
        <Instruction data-cy="photo-instruction-description">
          {photoRequirement.description}
        </Instruction>
        {photoRequirement.imageUrl && (
          <ImageContainer>
            <SampleImageBanner>Sample image</SampleImageBanner>
            <SampleImage src={photoRequirement.imageUrl} data-cy="photo-instruction-sample-image" />
          </ImageContainer>
        )}
        {isUploadingPhotos && (
          <ImageInputContainer>
            <ImageInput
              key={photoRequirement.id}
              id="image-input"
              data-cy="upload-image-input"
              onChange={handleImageChange}
              imageExtensions={SUPPORTED_FILE_EXTENSIONS}
              maxSizeMb={MAX_FILE_SIZE_MB}
              height={160}
              isCustomerFacing
              width={240}
              isError={isPhotoRejected}
              errorFeedback="That image doesn’t look quite right. Please ensure it meets our submission criteria."
            />
          </ImageInputContainer>
        )}
      </ImageAndInstruction>
    </InstructionWrapper>
  )
}

const InstructionWrapper = styled.div({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'flex-start',
  gap: '8px',
  alignSelf: 'stretch',
})

const InstructionIndex = styled.div({
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
})

const ImageAndInstruction = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '16px',
})

const Instruction = styled.div({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
})

const ImageContainer = styled.div({
  position: 'relative',
  width: '240px',
  height: '160px',
})

const SampleImage = styled.img({
  width: '100%',
  height: '100%',
  borderRadius: '8px',
  objectFit: 'cover',
})

const SampleImageBanner = styled.div({
  display: 'flex',
  width: '100%',
  height: '32px',
  padding: '8px',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'absolute',
  bottom: '0px',
  borderRadius: '0px 0px 8px 8px',
  background: 'var(--neutral-opacity-50, rgba(83, 102, 119, 0.50))',
  color: COLOR.WHITE,
})

const ImageInputContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  gap: 8,
})

export { PhotoInstruction }

import type { ChangeEvent, FC } from 'react'
import React, { useEffect, useRef, useState } from 'react'
import Select from 'react-select'
import type { SelectInstance, SingleValue } from 'react-select'
import { customLogger } from '@extend/client-helpers'
import type { Address } from '@customers-api-client'
import {
  getDetails,
  getPlacePredictions,
  initServices,
  getFullAddress,
  getNewOptions,
} from '../../../../../lib/google-api'
import type { AutocompletePrediction, OptionType } from '../../../../../lib/google-api'
import { svgs } from '../../../../../lib/assets'
import { MailButton } from '../../mail-button'
import {
  customSelectStyles,
  InlineWrapper,
  MailButtonWrapper,
  MobileSeparator,
  StyledTextInput,
  Wrapper,
} from './styles'
import type { ChatAddressInputProps } from './types'

const ChatAddressInput: FC<ChatAddressInputProps> = ({ onAddInput, prompt }) => {
  const [isMapsServicesLoaded, setIsMapsServicesLoaded] = useState<boolean>(false)
  const [addressInput, setAddressInput] = useState<string>()
  const [unitNumberInput, setUnitNumberInput] = useState<string>()
  const [selectedOption, setSelectedOption] = useState<OptionType>()
  const [options, setOptions] = useState<OptionType[]>([])
  const [customerAddress, setCustomerAddress] = useState<Address>()
  const { slot } = prompt

  const reactSelectRef = useRef<SelectInstance<OptionType>>(null)

  useEffect(() => {
    if (!isMapsServicesLoaded) {
      const initGoogleApi = async (): Promise<void> => {
        await initServices()
        setIsMapsServicesLoaded(true)
      }
      initGoogleApi()
    }
    if (options.length && reactSelectRef?.current) {
      reactSelectRef.current.focusOption('last')
    }
  }, [isMapsServicesLoaded, options])

  const displaySuggestions = (predictions: AutocompletePrediction[] | null): void => {
    if (!predictions?.length) {
      setOptions([])
      return
    }
    setOptions(getNewOptions(predictions))
  }

  const handleInputChange = (inputValue: string): void => {
    setAddressInput(inputValue)
    if (!inputValue) {
      setOptions([])
      return
    }
    if (!isMapsServicesLoaded) return
    getPlacePredictions({ input: inputValue, types: ['address'] }, displaySuggestions)
  }

  const handleOptionChange = (option: SingleValue<OptionType>): void => {
    if (!option) return
    getDetails({ placeId: option.value, fields: ['address_component'] }, (result) => {
      if (!result?.address_components?.length) {
        customLogger.error(`Error occurred during Google Maps attempt to get address details`, {
          timestamp: Date.now(),
          errorMessage: `No address details returned for given Google Maps Prediction: ${JSON.stringify(
            { address: option.label, placeId: option.value },
          )}. Google Maps Result: ${JSON.stringify(result)}`,
        })
        return
      }
      setSelectedOption(option)
      setCustomerAddress(getFullAddress(result.address_components))
    })
  }

  const handleButtonClick = (): void => {
    if (!customerAddress) return
    const combinedAddress: Address = { ...customerAddress, address2: unitNumberInput }
    if (selectedOption) {
      const { address1, address2, city, provinceCode, postalCode, countryCode } = combinedAddress
      const optionalUnitNumber = address2 ? `${address2}, ` : ''
      const formattedAddress = `${address1}, ${optionalUnitNumber}${city}, ${provinceCode} ${postalCode}, ${countryCode}`
      onAddInput({
        message: formattedAddress,
        slot,
        value: combinedAddress,
      })
    }
  }

  const handleUnitNumberInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    event.preventDefault()
    const { value } = event.target
    setUnitNumberInput(value)
  }

  return (
    <Wrapper>
      <Select
        ref={reactSelectRef}
        styles={customSelectStyles}
        value={selectedOption}
        onChange={handleOptionChange}
        onInputChange={handleInputChange}
        options={options}
        menuPlacement="top"
        menuPortalTarget={document.body}
        components={{ DropdownIndicator: () => null, IndicatorSeparator: () => null }}
        placeholder="Street Address, City, State"
        menuIsOpen={!!addressInput}
      />
      <MobileSeparator />
      <InlineWrapper>
        <StyledTextInput
          type="text"
          placeholder="Apt/Unit # (Optional)"
          onChange={handleUnitNumberInputChange}
        />
        <MailButtonWrapper>
          <MailButton onClick={handleButtonClick} disabled={!selectedOption} aria-label="send">
            {selectedOption ? <svgs.MailEnabled /> : <svgs.MailDisabled />}
          </MailButton>
        </MailButtonWrapper>
      </InlineWrapper>
    </Wrapper>
  )
}

export { ChatAddressInput }

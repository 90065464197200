import React from 'react'
import styled from '@emotion/styled'
import { COLOR } from '@extend/zen'

import { DailyEventInfo } from './wismo-events'

interface WismoEventDayProps {
  date: string
  dailyEventInfo: DailyEventInfo[]
}

const WismoEventDay: React.FC<WismoEventDayProps> = ({ date, dailyEventInfo }) => {
  const capitalizeFirstLetter = (str: string): string => {
    const words = str.split(' ')
    const capitalizedWords = words.map((word) => {
      const firstLetter = word.charAt(0).toUpperCase()
      const restOfWord = word.slice(1).toLowerCase()
      return firstLetter + restOfWord
    })
    return capitalizedWords.join(' ')
  }

  return (
    <WismoEventDayContainer>
      <Date data-cy="date">{date}</Date>
      {dailyEventInfo.map((event) => (
        <DailyEventInfoContainer key={event.timestamp}>
          <EventDescription data-cy="event-description">{event.eventDescription}</EventDescription>
          <LocationContainer data-cy="event-location">
            <SoftText>{`${event.city ? capitalizeFirstLetter(event.city) : ''}${
              event.state ? ', ' + event.state.toUpperCase() : ''
            }`}</SoftText>
            <SoftText>{event.timestamp}</SoftText>
          </LocationContainer>
        </DailyEventInfoContainer>
      ))}
    </WismoEventDayContainer>
  )
}

const WismoEventDayContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: 8,
})

const Date = styled.div({
  fontSize: 14,
  color: COLOR.BLACK,
  fontWeight: 700,
  lineHeight: '18px',
  textTransform: 'uppercase',
})

const DailyEventInfoContainer = styled.div({
  display: 'flex',
  flexDirection: 'column',
})

const EventDescription = styled.div({
  fontSize: 16,
  lineHeight: '24px',
  color: COLOR.BLACK,
})

const LocationContainer = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
})

const SoftText = styled.div({
  color: COLOR.NEUTRAL[600],
  lineHeight: '22px',
})

export default WismoEventDay

import type { FC } from 'react'
import React from 'react'

const iMac: FC = () => {
  return (
    <svg width="53px" height="51px" viewBox="0 0 53 51" version="1.1">
      <title>Computer</title>
      <g id="Tire/Wheels-Plan-Details" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g
          id="Plan-Details-Copy"
          transform="translate(-743.000000, -1318.000000)"
          fillRule="nonzero"
        >
          <g id="Computer" transform="translate(743.000000, 1318.000000)">
            <path
              d="M51.8478261,33.0333333 L1.15217391,33.0333333 C0.516173913,33.0333333 0,32.3613333 0,31.5333333 C0,30.7053333 0.516173913,30.0333333 1.15217391,30.0333333 L51.8478261,30.0333333 C52.4838261,30.0333333 53,30.7053333 53,31.5333333 C53,32.3613333 52.4838261,33.0333333 51.8478261,33.0333333 Z"
              id="Path"
              fill="#27AEE4"
            />
            <path
              d="M36.4930023,50.2850648 C31.5647827,50.2850648 30.0847167,41.9394296 29.8367056,40.2666907 C29.7460349,39.6481611 30.2633913,39.0838092 30.9940906,39.0070574 C31.7167894,38.9393352 32.3888194,39.3659852 32.4848236,39.9867722 C32.9835125,43.3570815 34.655587,48.0276574 36.4930023,48.0276574 C37.229035,48.0276574 37.826395,48.5333166 37.826395,49.1563611 C37.826395,49.7794055 37.229035,50.2850648 36.4930023,50.2850648 Z"
              id="Path"
              fill="#224BC5"
            />
            <path
              d="M17.3329556,50.2850648 C16.5971641,50.2850648 16,49.7794055 16,49.1563611 C16,48.5333166 16.5971641,48.0276574 17.3329556,48.0276574 C19.1697684,48.0276574 20.8412947,43.3570815 21.342486,39.9867722 C21.4357929,39.3659852 22.1076025,38.9393352 22.8327303,39.0070574 C23.560524,39.0838092 24.0803767,39.6481611 23.9897357,40.2666907 C23.7391401,41.9394296 22.2595594,50.2850648 17.3329556,50.2850648 Z"
              id="Path"
              fill="#224BC5"
            />
            <path
              d="M47.2391304,41.1954545 L5.76086957,41.1954545 C2.58317391,41.1954545 0,38.6276045 0,35.4738636 L0,5.72159091 C0,2.56785 2.58317391,0 5.76086957,0 L47.2391304,0 C50.4168261,0 53,2.56785 53,5.72159091 L53,35.4738636 C53,38.6276045 50.4168261,41.1954545 47.2391304,41.1954545 Z M6.82142857,3.25 C4.99021429,3.25 3.5,4.713775 3.5,6.5125 L3.5,34.7875 C3.5,36.586225 4.99021429,38.05 6.82142857,38.05 L46.6785714,38.05 C48.5097857,38.05 50,36.586225 50,34.7875 L50,6.5125 C50,4.713775 48.5097857,3.25 46.6785714,3.25 L6.82142857,3.25 Z M40.326087,50.5613636 L12.673913,50.5613636 C12.037913,50.5613636 11.5217391,50.0013636 11.5217391,49.3113636 C11.5217391,48.6213636 12.037913,48.0613636 12.673913,48.0613636 L40.326087,48.0613636 C40.962087,48.0613636 41.4782609,48.6213636 41.4782609,49.3113636 C41.4782609,50.0013636 40.962087,50.5613636 40.326087,50.5613636 Z"
              id="Shape"
              fill="#224BC5"
            />
          </g>
        </g>
      </g>
    </svg>
  )
}

export { iMac }

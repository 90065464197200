import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { Badge, COLOR, OpenInNew, Subheading, Package, Icon } from '@extend/zen'
import {
  useGetMerchantServicingSettingsQuery,
  EmsProduct,
  EntitlementSearchByOrdersResponse,
  EmsProductWithShipmentInfo,
} from '@customers-api-rtk-query'
import { bp } from '@customers-ui'
import { useTheme } from '@emotion/react'
import { isMobile } from 'react-device-detect'
import { usePrecheckEmsData } from '@src/hooks'
import {
  formatDate,
  getIsClaimInProgress,
  getShipmentIssueTooltip,
  mapContractStatusBadge,
  missingProfileFieldTooltip,
} from '../../../lib/helper-functions'
import { MappedProduct, mapTrackingData } from '../../../lib/shipping-protection-utils'
import { Card, CardGroupItemProps } from '../../../components/card'
import { images } from '../../../lib/assets'
import { ProductProtectionInlineBadge } from './product-protection-badge'
import { toTitleCase } from '../../../utils/string-utils'
import { getValidationErrorMessages } from '../utils'

export interface ShippingProtectionCardProps {
  emsData: EntitlementSearchByOrdersResponse
  onFileClaimClick: (handlerArgs: {
    contractId?: string
    orderId?: string
    isMerchantOwnedIntake?: boolean
    lineItemIds?: string[]
    shipmentId?: string
  }) => void
  isMissingRequiredProfileField?: boolean
}

const ShippingProtectionCard: FC<ShippingProtectionCardProps> = ({
  emsData,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const theme = useTheme()
  // the types are currently messed up due to refactoring
  const isExtend = (theme as any)?.merchantConfiguration?.isExtend
  const { name: sellerName, id: sellerId } = emsData.store
  const transactionDate = emsData?.products?.[0]?.transactionDate
  const isShippingResolution = emsData.products.some((product) =>
    product.entitlements?.some((entitlement) => entitlement.type === 'shipping_resolution'),
  )
  const hasShippingResolutionAndProtectionEntitlement =
    isShippingResolution &&
    emsData.products.find((product) =>
      product.entitlements?.some((entitlement) => entitlement.type === 'shipping_protection'),
    )

  const {
    data: mappedPrecheckData,
    isClaimInProgress,
    claimInProgressSubBadgeText,
  } = usePrecheckEmsData(emsData)
  const isLoading = !mappedPrecheckData

  const contractId =
    !isShippingResolution && emsData.contracts
      ? Object.values(emsData.contracts)?.find(
          (contract) => contract.type === 'shipping_protection',
        )?.id
      : undefined
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId,
  })

  const { trackedProducts, untrackedProducts } = mapTrackingData(emsData)

  const getTitleHeading = () => {
    const productCount = emsData.products.length

    if (productCount === 1) {
      return `${emsData.products[0].title}`
    } else if (productCount === 2) {
      return `${emsData.products[0].title} and 1 item`
    } else if (productCount > 2) {
      return `${emsData.products[0].title} and ${productCount - 1} items`
    } else {
      return `Order from ${sellerName}`
    }
  }

  const shippingStatus = trackedProducts?.length
    ? trackedProducts.find((product) => product.trackingInfo?.status)?.trackingInfo?.status
    : undefined
  const shippingResolutionBadgeText = shippingStatus
    ? toTitleCase(shippingStatus)
    : 'Pending Shipment Details'
  const shippingProtectionPrecheckData = mappedPrecheckData.find(
    ({ contract }) => contract?.type === 'shipping_protection',
  )
  const shippingProtectionDisplayDetails = shippingProtectionPrecheckData?.displayDetails
  const shippingResolutionDisplayDetails = mappedPrecheckData.find(
    (data) => !data.contract,
  )?.displayDetails

  const BadgePreHeading = () => {
    return (
      <BadgeWrapper>
        <TopRow>
          {!isShippingResolution && !isMobile && <Icon icon={Package} color={COLOR.NEUTRAL[600]} />}
          {!isShippingResolution && (
            <StyledSubheading data-cy="sp-card-subheading">Shipping Protection</StyledSubheading>
          )}
          {!isLoading && !isShippingResolution && (
            <Badge
              text={mapContractStatusBadge(shippingProtectionPrecheckData?.contract?.status || '')}
              color={shippingProtectionDisplayDetails?.badgeColor}
              data-cy="sp-card-badge"
              icon={isMobile ? Package : undefined}
            />
          )}
          {!isLoading && isShippingResolution && (
            <Badge
              text={shippingResolutionBadgeText}
              color={shippingResolutionDisplayDetails?.badgeColor}
              data-cy="sp-card-badge"
              icon={Package}
            />
          )}
        </TopRow>
        {!isLoading && isClaimInProgress && (
          <ClaimInProgressWrapper>
            <Badge
              emphasis="low"
              text={claimInProgressSubBadgeText}
              data-cy="sp-card-claim-progress-badge"
              color="green"
            />
          </ClaimInProgressWrapper>
        )}
      </BadgeWrapper>
    )
  }

  const generatePrimaryButtonProps = ({
    trackingInfo,
    products,
  }: {
    trackingInfo?: EmsProduct['tracking']
    products: MappedProduct[]
  }) => {
    const shippingProtectionData = mappedPrecheckData?.find(
      (data) => data.type === 'shipping_resolution' || data.type === 'shipping_protection',
    )
    const { hasActiveClaim, displayDetails, precheckResponse } = shippingProtectionData || {}
    const shipmentProducts = products.map((product) => product.product)
    const hasActiveClaimOnShipment =
      hasActiveClaim && getIsClaimInProgress(precheckResponse, shipmentProducts)

    const shipmentIssueTooltip = getShipmentIssueTooltip({
      isMissingRequiredProfileField,
      fileClaimTooltip: displayDetails?.fileClaimTooltip,
      hasActiveClaim: hasActiveClaimOnShipment,
      hasTrackingInfo: !!trackingInfo,
    })

    return {
      emphasis: 'medium',
      text: 'Report Shipment Issue',
      isDisabled:
        // do not enable if extend domain or if both SR & SP in one order
        (isExtend && hasShippingResolutionAndProtectionEntitlement) ||
        hasShippingResolutionAndProtectionEntitlement ||
        !mappedPrecheckData ||
        displayDetails?.isClaimFilingDisabled ||
        isMissingRequiredProfileField ||
        hasActiveClaimOnShipment,
      tooltip: shipmentIssueTooltip,
      onClick: () =>
        onFileClaimClick({
          contractId,
          orderId: isShippingResolution ? emsData.id : undefined,
          isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
          lineItemIds: products
            .filter(({ hasEntitlement }) => hasEntitlement)
            .map(({ product }) => product.lineItemId),
          shipmentId: trackingInfo?.id,
        }),
    }
  }

  const generateSecondaryButtonProps = (opts?: {
    trackingInfo?: EmsProduct['tracking']
    shipmentInfo?: EmsProductWithShipmentInfo['shipmentInfo']
  }) => {
    const { trackingInfo, shipmentInfo } = opts || {}
    const trackingUrl =
      trackingInfo && 'requestTrackingUrl' in trackingInfo
        ? trackingInfo.requestTrackingUrl
        : trackingInfo?.trackingUrl || shipmentInfo?.trackingUrl

    return {
      emphasis: 'medium',
      icon: OpenInNew,
      text: 'Track Package',
      isDisabled: !trackingUrl,
      onClick: () => {
        if (trackingUrl) {
          window.open(trackingUrl, '_blank')
        }
      },
      tooltip: 'Track on carrier website',
    }
  }

  const generateListItems = (products: MappedProduct[]) =>
    products.map(({ product, hasProductProtection, contractId, hasEntitlement }) => {
      const precheck = mappedPrecheckData?.find((data) => data.contract?.id === contractId)
      const { displayDetails, hasActiveClaim, precheckResponse } = precheck || {}
      const isLineItemClaimInProgress =
        hasActiveClaim && getIsClaimInProgress(precheckResponse, [product])
      const activeClaimTooltip = isLineItemClaimInProgress
        ? getValidationErrorMessages('active_claim_found').active_claim_found.default
            .fileClaimTooltip
        : ''
      return {
        image: product?.imageUrl || images.fallbackImage,
        text: product?.title,
        ...(!hasEntitlement && { subText: "This item isn't eligible for shipping issues" }),
        itemsShowMoreText: 'View all items',
        itemsShowLessText: 'View less items',
        ...(hasProductProtection && {
          subText: (
            <ProductProtectionInlineBadge
              isClaimInProgress={isLineItemClaimInProgress || false}
              subBadgeText={displayDetails?.subBadgeText}
              isLoading={isLoading}
              badgeColor={displayDetails?.badgeColor}
              badgeDetails={displayDetails?.badgeDetails || ''}
            />
          ),
          primaryButtonProps: {
            emphasis: 'medium',
            text: 'File a Claim',
            isDisabled:
              !precheck ||
              displayDetails?.isClaimFilingDisabled ||
              isLineItemClaimInProgress ||
              isMissingRequiredProfileField ||
              !hasEntitlement,
            tooltip: isMissingRequiredProfileField
              ? missingProfileFieldTooltip
              : activeClaimTooltip,
            onClick: () =>
              onFileClaimClick({
                contractId: product.entitlements?.length
                  ? product.entitlements.find(
                      (entitlement) => entitlement.type === 'product_protection',
                    )?.contractId
                  : undefined,
                isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
                lineItemIds: [product.lineItemId],
              }),
          },
        }),
      }
    })

  const trackedGroups = trackedProducts?.map(({ trackingInfo, shipmentInfo, products }, index) => {
    if ((trackingInfo || shipmentInfo) && products.length) {
      return {
        preHeading: `Ship ${index + 1} · `,
        heading: trackingInfo?.actualDeliveryDate
          ? `Delivered ${formatDate(trackingInfo.actualDeliveryDate)}`
          : trackingInfo?.estimatedDeliveryDate
          ? `Arriving ${formatDate(trackingInfo.estimatedDeliveryDate)}`
          : 'Pending shipment details',
        primaryButtonProps: generatePrimaryButtonProps({ trackingInfo, products }),
        secondaryButtonProps: generateSecondaryButtonProps({ trackingInfo, shipmentInfo }),
        listItems: generateListItems(products),
        showMoreGroupsText: 'View all shipments',
        showLessGroupsText: 'View less shipments',
      }
    }
  }) as CardGroupItemProps[]

  const untrackedGroup =
    untrackedProducts?.length &&
    ({
      heading: 'Pending shipment details',
      primaryButtonProps: generatePrimaryButtonProps({ products: untrackedProducts }),
      secondaryButtonProps: generateSecondaryButtonProps(),
      listItems: generateListItems(untrackedProducts),
    } as CardGroupItemProps)

  const titleHeading = isShippingResolution ? `${getTitleHeading()}` : `Order from ${sellerName}`
  return (
    <Card
      data-cy="shipping-protection"
      dataProps={{
        'data-extend-live': 'shipping-protection-card-wrapper',
        'data-order-id': emsData.id,
        ...(isShippingResolution && {
          'data-extend-live-sr-id': emsData.id,
        }),
        ...(!isShippingResolution && {
          'data-extend-live-sp-id': contractId,
        }),
      }}
      // TODO: [POST-2355] remove override
      extendLiveOverride="shipping-protection-card"
      preHeading={<BadgePreHeading />}
      heading={titleHeading}
      subHeading={`Order # ${emsData.transactionId} · ${trackedGroups?.length ?? 0} shipment(s) · ${
        emsData.products?.length
      } item(s) · Purchased ${formatDate(transactionDate || 0)}`}
      primaryGroups={trackedGroups || undefined}
      secondaryGroups={untrackedGroup ? [untrackedGroup] : undefined}
    />
  )
}

const BadgeWrapper = styled.div({
  display: 'flex',
  gap: '8px',
  alignItems: 'center',
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const TopRow = styled.div({
  display: 'flex',
  alignItems: 'center',
  gap: '8px',
})

const StyledSubheading = styled(Subheading)({
  color: COLOR.NEUTRAL[600],
  fontWeight: 700,
})

const ClaimInProgressWrapper = styled.div({
  alignSelf: 'flex-start',
})

export { ShippingProtectionCard }

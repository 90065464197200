import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import type { PlanDetails } from '@customers-api-client'

interface HeroProps {
  planDetails: PlanDetails
}

const Hero: FC<HeroProps> = ({ planDetails }) => {
  return (
    <>
      <Header>{planDetails.planDetails.header}</Header>
      <Subheader>
        <SubheaderPlanName data-cy="plan-name">
          {planDetails.planDetails.headerPlanName}
        </SubheaderPlanName>
        <SubheaderTagline>{planDetails.planDetails.headerTagline}</SubheaderTagline>
      </Subheader>
      <BannerWrapper>
        <BannerImage src={planDetails.planDetails.headerBannerImage} alt="hero" />
      </BannerWrapper>
    </>
  )
}

const Header = styled.h1({
  fontWeight: 400,
  textAlign: 'left',
  margin: '0 0 8px 0',
  marginBottom: 4,
  letterSpacing: '1px',
  textTransform: 'uppercase',
  fontSize: 20,
  lineHeight: '35px',
  [bp.maxWidthMd]: {
    fontSize: 13,
    lineHeight: '22px',
  },
})

const BannerWrapper = styled.div({
  backgroundColor: COLOR.NEUTRAL[200],
  position: 'relative',
  height: 108,
  margin: '0 auto',
  width: 250,
  zIndex: -1, // to send calculator widget to top
  [bp.sm]: {
    height: 147,
    width: 350,
  },
  [bp.md]: {
    height: 277,
    width: 688,
  },
  [bp.lg]: {
    height: 373,
    width: 928,
  },
  [bp.xl]: {
    width: 1227,
    height: 494,
  },
  [bp.maxWidth]: {
    height: 586,
    width: '100%',
  },
})

const BannerImage = styled.img({
  width: '100%',
})

const Subheader = styled.section({
  padding: 0,
  // 'block' for ie11 support
  display: 'block',
  marginBottom: 30,
  alignItems: 'flex-start',
  alignSelf: 'flex-start',
  flexDirection: 'column',
  '& p': {
    paddingLeft: 0,
  },
  [bp.md]: {
    marginBottom: 48,
  },
})

const SubheaderPlanName = styled.h2({
  fontWeight: 700,
  flex: 1,
  margin: '8px 0 16px 0',
  fontSize: 45,
  lineHeight: '57px',
  [bp.xl]: {
    paddingRight: 20,
  },
  [bp.maxWidthMd]: {
    fontSize: 23,
    lineHeight: '27px',
  },
})

const SubheaderTagline = styled.p({
  flex: 1,
  margin: 0,
  marginTop: 8,
  paddingLeft: 20,
  fontSize: 20,
  lineHeight: '30px',
  [bp.md]: {
    marginTop: 0,
  },
  [bp.maxWidthMd]: {
    fontSize: 16,
    lineHeight: '24px',
  },
})

export { Hero }

import { useEffect, useState, useCallback } from 'react'
import { useGetMarqetaAccessTokenQuery } from '@customers-api-rtk-query'
import { COLOR } from '@extend/zen'
import inRange from 'lodash/inRange'

const MARQETA_URL =
  __EXTEND_ENV__ === 'production'
    ? 'https://widgets.marqeta.com/marqetajs/1.1.0/marqeta.min.js'
    : 'https://widgets-sandbox.marqeta.com/marqetajs/1.1.0/marqeta.min.js'

const getFontSize = (windowWidth: number): number => {
  if (inRange(windowWidth, 0, 520)) return 12
  if (inRange(windowWidth, 520, 800)) return 20
  return 24
}

const useMarqeta = (
  {
    walletId,
    accessToken,
    windowWidth,
  }: { walletId: string; accessToken?: string; windowWidth: number },
  { isSkipped }: { isSkipped: boolean },
): { isMarqetaLoaded: boolean; isError: boolean } => {
  const { clientAccessToken } = useGetMarqetaAccessTokenQuery(
    { walletId, accessToken },
    {
      skip: isSkipped || !walletId,
      selectFromResult: ({ data, ...rest }) => ({
        clientAccessToken: data?.accessToken ?? '',
        ...rest,
      }),
    },
  )
  const [isMarqetaLoaded, setIsMarqetaLoaded] = useState(false)
  const [isBootstrapped, setIsBootstrapped] = useState(false)
  const [isError, setIsError] = useState(false)
  const fontSize = getFontSize(windowWidth)

  const handleMarqetaLoaded = useCallback((): void => {
    setIsMarqetaLoaded(!isMarqetaLoaded)
  }, [isMarqetaLoaded])

  useEffect(() => {
    if (isSkipped) return () => {}

    const script = document.createElement('script')

    if (!isMarqetaLoaded) {
      script.id = 'marqeta'
      script.type = 'text/javascript'
      script.src = MARQETA_URL
      document.body.appendChild(script)
      script.addEventListener('load', handleMarqetaLoaded)
    }

    return () => {
      script.removeEventListener('load', handleMarqetaLoaded)
    }
  }, [handleMarqetaLoaded, isMarqetaLoaded, isSkipped])

  useEffect(() => {
    if (isMarqetaLoaded && !isBootstrapped && clientAccessToken) {
      marqeta.bootstrap({
        clientAccessToken,
        containerId: 'virtual-card-container',
        integrationType: 'custom',
        showPan: {
          cardPan: {
            domId: 'mq-card-pan',
            format: true,
            styles: {
              span: {
                'font-family': 'monospace',
                'font-size': `${fontSize + 4}px`,
                color: COLOR.WHITE,
              },
            },
          },
          cardExp: {
            domId: 'mq-card-exp',
            format: true,
            styles: {
              span: {
                'font-family': 'monospace',
                'font-size': `${fontSize}px`,
                color: COLOR.WHITE,
              },
            },
          },
          cardCvv: {
            domId: 'mq-card-cvv',
            styles: {
              span: {
                'font-family': 'monospace',
                'font-size': `${fontSize}px`,
                color: COLOR.WHITE,
              },
            },
          },
          copyCardPan: {
            domId: 'mq-card-copy',
            mode: 'transparent',
          },
        },
        callbackEvents: {
          onSuccess: () => {
            setIsBootstrapped(true)
          },
          onFailure: () => {
            setIsError(true)
          },
        },
      })
    }
  }, [isMarqetaLoaded, isBootstrapped, clientAccessToken, fontSize])

  return { isMarqetaLoaded, isError }
}

export { useMarqeta }

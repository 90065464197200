import type { FC, ChangeEvent, ReactNode, ReactPortal } from 'react'
import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import DatePicker from 'react-datepicker'
import styled from '@emotion/styled'
import { COLOR } from '@customers-ui'
import { bp } from '@extend/client-helpers'
import 'react-datepicker/dist/react-datepicker.css'
import { svgs } from '../../../../../lib/assets'
import { useScreenSize } from '../../../../../hooks'
import { chatActions } from '../../../../../actions'
import * as selectors from '../../../../../reducers/selectors'
import type { RootState } from '../../../../../reducers'
import { Button } from '../../button'
import { MailButton } from '../../mail-button'
import { ChatDateGlobalStyles } from './chat-date-global-styles'

const { CalendarIcon, MailEnabled, MailDisabled } = svgs

interface StateProps {
  prompt: ReturnType<typeof selectors.getChatPrompt>
}

interface DispatchProps {
  onAddInput: typeof chatActions.chatSessionUpdate
}

interface PopperContainerProps {
  children: ReactNode[]
}

type ChatDateInputProps = StateProps & DispatchProps

const Component: FC<ChatDateInputProps> = ({ onAddInput, prompt }) => {
  const screenSize = useScreenSize()
  const [currentDate, setCurrentDate] = useState<Date>()
  const [inputDisabled, setInputDisabled] = useState(false)

  const handleButtonClick = (): void => {
    if (!currentDate) return
    const message = formatDate(currentDate, { dateStyle: 'medium' })
    const value = currentDate.getTime()
    if (prompt && prompt.slot) {
      const { slot } = prompt
      onAddInput({ message, slot, value })
    }
  }

  const handleChange = (nextDate: Date | null): void => {
    if (nextDate) {
      setCurrentDate(nextDate)
      setInputDisabled(false)
    }
  }

  const handleChangeRaw = (event: ChangeEvent<HTMLInputElement>): void => {
    const rawDate = event.target.value
    const notRealDate = Number.isNaN(Date.parse(rawDate))
    const isDisabled = notRealDate || new Date(rawDate) > new Date()
    setInputDisabled(isDisabled)
  }

  const disabled = !currentDate || inputDisabled

  const inputButton =
    screenSize === 'small' ? (
      <DateButton onClick={handleButtonClick} disabled={!currentDate}>
        {currentDate
          ? `It was on ${formatDate(currentDate, { dateStyle: 'long' })}`
          : 'Please pick a date'}
      </DateButton>
    ) : (
      <MailButtonWrapper>
        <MailButton onClick={handleButtonClick} disabled={disabled} aria-label="send">
          {disabled ? <MailDisabled /> : <MailEnabled />}
        </MailButton>
      </MailButtonWrapper>
    )

  // https://helloextend.atlassian.net/browse/CIAO-76
  // solution discovered here: https://github.com/Hacker0x01/react-datepicker/pull/2152#issuecomment-634169734
  const PopperContainer: FC<PopperContainerProps> = ({ children }): ReactPortal => {
    return ReactDOM.createPortal(children, document.body)
  }

  return (
    <DateWrapper data-cy="chat-date-input">
      <ChatDateGlobalStyles />
      <VisuallyHiddenLabel htmlFor="datePicker">Pick a date:</VisuallyHiddenLabel>
      <DatePicker
        id="datePicker"
        onChange={handleChange}
        inline={screenSize === 'small'}
        maxDate={new Date()}
        selected={currentDate}
        placeholderText={
          screenSize !== 'small' ? formatDate(new Date(), { dateStyle: 'medium' }) : ''
        }
        disabledKeyboardNavigation
        onChangeRaw={handleChangeRaw}
        popperPlacement="top"
        popperModifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            escapeWithReference: false,
            boundariesElement: 'viewport',
          },
        }}
        popperContainer={PopperContainer}
        dateFormat="MMM dd, yyyy"
      />
      {screenSize !== 'small' && (
        <CalendarIconWrapper>
          <CalendarIcon />
        </CalendarIconWrapper>
      )}
      {inputButton}
    </DateWrapper>
  )
}

const formatDate = (displayDate: Date, monthOptions = {}): string =>
  displayDate.toLocaleDateString('en-US', { ...monthOptions })

const VisuallyHiddenLabel = styled.label({
  border: '0',
  clip: 'rect(0 0 0 0)',
  height: '1px',
  margin: '-1px',
  overflow: 'hidden',
  padding: '0',
  position: 'absolute',
  width: '1px',
})

const DateWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  position: 'relative',
  [bp.md]: {
    padding: '28px 0px',
    flexDirection: 'row',
  },
})

const MailButtonWrapper = styled.div({
  display: 'flex',
  alignSelf: 'center',
})

const DateButton = styled(Button)({
  marginBottom: '24px',
  ':disabled, :disabled:hover': {
    border: `1px solid ${COLOR.NEUTRAL[500]}`,
    color: COLOR.NEUTRAL[500],
  },
})

const CalendarIconWrapper = styled.div({
  alignSelf: 'center',
  display: 'flex',
  marginLeft: '-30px',
  paddingRight: '12px',
})

const ChatDateInput = connect(
  (state: RootState): StateProps => ({
    prompt: selectors.getChatPrompt(state),
  }),
  {
    onAddInput: chatActions.chatSessionUpdate,
  } as DispatchProps,
)(Component)

export type { ChatDateInputProps }
export { Component, ChatDateInput, formatDate }

import { usePrecheckAllQuery, EntitlementSearchByOrdersResponse, PrecheckAllRequest } from '@customers-api-rtk-query'
import { getDisplayDetails } from '../pages/plans-page/utils'
import { getIsClaimInProgress } from '@src/lib/helper-functions'

export const usePrecheckEmsData = (emsData: EntitlementSearchByOrdersResponse) => {
  const hasShippingResolution = emsData.products.some((product) =>
    product.entitlements?.some((entitlement) => entitlement.type === 'shipping_resolution')
 ) 

  const queries: PrecheckAllRequest = []
  if (hasShippingResolution) {
    queries.push({ orderId: emsData.id })
  }
  if (emsData.contracts) {
    Object.keys(emsData.contracts).forEach(contractId => {
      queries.push({ contractId, contract: emsData?.contracts?.[contractId] })
    })
  }

  const { data } = usePrecheckAllQuery(queries)

  let isClaimInProgress = false
  let subBadgeText = ''
  const precheckAllData = Object.entries(data ?? {}).map(([_id, { contract, precheckResponse }]) => {
    const type = contract?.type
    const status = contract?.status

    const displayDetails = getDisplayDetails({ precheck: precheckResponse, status, type })
    const hasActiveClaim = getIsClaimInProgress(precheckResponse, emsData.products)
    isClaimInProgress = !isClaimInProgress && hasActiveClaim
    subBadgeText = isClaimInProgress && displayDetails.subBadgeText || 'Claim in Progress'
    return {
      displayDetails,
      contract,
      precheckResponse,
      hasActiveClaim,
      type: !contract ? 'shipping_resolution' : contract?.type,
    }
  })

  return {
    data: precheckAllData,
    isClaimInProgress,
    claimInProgressSubBadgeText: subBadgeText,
  }
} 
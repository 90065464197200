import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { Badge, Shield } from '@extend/zen'
import { isMobile } from 'react-device-detect'
import { useGetMerchantServicingSettingsQuery, usePrecheckQuery } from '@customers-api-rtk-query'
import {
  formatDate,
  getIsClaimInProgress,
  getDisplayDetails,
  getValidationErrorMessages,
  missingProfileFieldTooltip,
} from '../../../lib/helper-functions'
import { logEvent } from '../../../analytics'
import type { CategoryContract, CategoryProduct } from '../../../types/contract'
import { getCanTransfer } from '../../contract-transfer/contract-transfer.utils'
import { isPrecheckWithLineItems } from '../../../lib/type-guards'
import { Card, CardListItemProps } from '../../../components/card'

export interface CategoryCardProps {
  contract: CategoryContract
  onFileClaimClick: (handlerArgs: {
    contractId: string
    lineItemIds?: string[]
    isMerchantOwnedIntake?: boolean
  }) => void
  isMissingRequiredProfileField?: boolean
}

const CategoryCard: FC<CategoryCardProps> = ({
  contract,
  onFileClaimClick,
  isMissingRequiredProfileField = false,
}) => {
  const history = useHistory()
  const { data: precheck, isLoading } = usePrecheckQuery({ contractId: contract.id })

  const { sellerName, purchaseDate, productsList, id } = contract
  const { data: merchantServicingSettings } = useGetMerchantServicingSettingsQuery({
    sellerId: contract.sellerId,
  })

  const handleTransferPlan = (): void => {
    logEvent('My Plans - Contract Card - Clicks', 'Transfer Plan')
    history.push(`/my_plans/${id}/transfer`)
  }

  const { isClaimFilingDisabled, fileClaimTooltip, badgeDetails, badgeColor } = getDisplayDetails({
    precheck,
    contract,
  })

  const hasActiveClaim =
    isPrecheckWithLineItems(precheck) &&
    Object.values(precheck.lineItems).some((lineItem) => lineItem.hasActiveClaim)

  const hasClaimPendingPhotoUploads =
    hasActiveClaim &&
    Object.values(precheck.lineItems).some((lineItem) => lineItem.hasClaimPendingPhotoUploads)

  const claimInProgressBadge = hasClaimPendingPhotoUploads
    ? getValidationErrorMessages(contract).pending_photo_uploads.default.subBadgeText
    : getValidationErrorMessages(contract).active_claim_found.default.subBadgeText

  const isClaimInProgress = getIsClaimInProgress(precheck, productsList)
  const BadgeSubText = () => {
    return (
      <BadgeWrapper>
        <Badge
          text="Product Protection"
          color="blue"
          data-cy="category-coverage-badge"
          icon={Shield}
        />
        {!isLoading && (
          <>
            {isClaimInProgress ? (
              <Badge
                emphasis="low"
                text={claimInProgressBadge || 'Claim in Progress'}
                data-cy="category-claim-progress-badge"
                color="green"
              />
            ) : (
              <Badge
                emphasis="low"
                text={badgeDetails}
                color={badgeColor}
                data-cy="category-coverage-badge"
              />
            )}
          </>
        )}
      </BadgeWrapper>
    )
  }

  const headerMenuItems = [
    ...(getCanTransfer({ contract, hasActiveClaim })
      ? [
          {
            text: 'Transfer Plan for All Items',
            onClick: handleTransferPlan,
          },
        ]
      : []),
  ]

  const listItems = productsList?.map((product) => {
    const { lineItemId } = product as CategoryProduct

    const lineItems = isPrecheckWithLineItems(precheck) ? precheck.lineItems : {}
    const shouldDisableClaimFiling =
      !precheck ||
      isMissingRequiredProfileField ||
      isClaimFilingDisabled ||
      (lineItems && lineItems[lineItemId]?.hasActiveClaim)

    let toolTipOverride = fileClaimTooltip
    if (
      shouldDisableClaimFiling &&
      lineItems &&
      lineItems[lineItemId]?.hasClaimPendingPhotoUploads
    ) {
      toolTipOverride =
        getValidationErrorMessages(contract).pending_photo_uploads.default.fileClaimTooltip
    } else if (shouldDisableClaimFiling) {
      toolTipOverride =
        getValidationErrorMessages(contract).active_claim_found.default.fileClaimTooltip
    }

    return {
      image: product?.imageUrl,
      text: (
        <ProductTitle
          onClick={() => history.push(`/my_plans/${id}/product/${lineItemId}`)}
          data-cy={`category-product-title-${lineItemId}`}
        >
          {product?.title}
        </ProductTitle>
      ),
      subText: <BadgeSubText />,
      primaryButtonProps: {
        text: 'File a Claim',
        emphasis: 'medium',
        onClick: () => {
          onFileClaimClick({
            contractId: contract.id,
            lineItemIds: [lineItemId],
            isMerchantOwnedIntake: merchantServicingSettings?.isMerchantOwnedIntake,
          })
        },
        isDisabled: shouldDisableClaimFiling,
        tooltip: isMissingRequiredProfileField ? missingProfileFieldTooltip : toolTipOverride,
      },
      menuItems: isMobile ? headerMenuItems : undefined,
    }
  }) as CardListItemProps[]

  return (
    <Card
      data-cy="category"
      dataProps={{
        'data-extend-live': 'category-card-wrapper',
        'data-contract-id': id,
        'data-extend-live-cat-id': id,
      }}
      heading={`Order from ${sellerName}`}
      subHeading={`Purchased ${formatDate(purchaseDate)}`}
      menuItems={!isMobile ? headerMenuItems : undefined}
      primaryGroups={[
        {
          listItems,
          itemsShowMoreText: 'View all items',
          itemsShowLessText: 'View less items',
        },
      ]}
    />
  )
}

const BadgeWrapper = styled.div({
  display: 'inline-flex',
  gap: 8,
  whiteSpace: 'nowrap',
})

const ProductTitle = styled.div({
  '&:hover': {
    cursor: 'pointer',
    textDecoration: 'underline',
  },
})

export { CategoryCard }

import React, { useEffect, useMemo, useState } from 'react'
import type { FC } from 'react'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import {
  useGetSessionLogsListQuery,
  useListInsuranceClaimsQuery,
  useSubmitClaimMutation,
  getRTKQueryErrorMessage,
} from '@customers-api-rtk-query'
import styled from '@emotion/styled'
import { ContentLayout, Spinner, bp } from '@customers-ui'
import { AccordionSection, COLOR, InlineAlert, InlineAlertColor } from '@extend/zen'
import { isEmpty } from 'lodash'
import { customLogger } from '@extend/client-helpers'
import * as selectors from '../../reducers/selectors'
import { ClaimSnapshot } from './claim-snapshot'
import { transformSessionLogs } from '../../lib/helper-functions'
import { CustomerShippingAddress } from './customer-shipping-address'
import type { Claim } from '../../types/claim'
import { ClaimResultModal } from '../../components/claim-result-modal/claim-result-modal'
import { getAccountInfo } from '../../lib/consumer-profile-utils'
import { setPollState } from '../../store/slices/poll'

const ReviewClaimSubmissionPage: FC = () => {
  const [hasLoadedClaim, setHasLoadedClaim] = useState(false)
  const location = useLocation()
  const dispatch = useDispatch()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search])
  const history = useHistory()
  const claimId = queryParams.get('claimId') || ''
  const sessionId = queryParams.get('sessionId') || ''
  const [isModalOpen, setModalOpen] = useState(false)

  const consumerProfile = useSelector(selectors.getConsumerProfile)
  const { isEmailVerified, isPhoneNumberVerified } = getAccountInfo(consumerProfile)
  const isMissingRequiredProfileField = !isEmailVerified || !isPhoneNumberVerified

  const decodedAccessToken = useSelector(selectors.getDecodedAccessToken)
  const poll = useSelector(selectors.getPoll)

  const email = poll?.customerEmail || decodedAccessToken?.email
  const phone = poll?.customerPhone || decodedAccessToken?.phone_number
  const pollSessionId = poll?.sessionId || sessionId

  const { data: sessionLogs, isLoading: isSessionLogsLoading } = useGetSessionLogsListQuery(
    pollSessionId,
    {
      selectFromResult: (response) => {
        const sortedData = response.data
          ? [...response.data].sort((a, b) => a.createdAt - b.createdAt)
          : []

        return {
          ...response,
          data: transformSessionLogs(sortedData),
        }
      },
    },
  )
  const {
    data: { items: fetchedClaims } = {},
    isLoading: isClaimsLoading,
    isError: isListClaimsError,
    error: listClaimsError,
  } = useListInsuranceClaimsQuery(
    {
      containsCustomerEmail: email,
      containsCustomerPhone: phone,
      sessionId: pollSessionId,
      minLimit: 500,
      ...(claimId && { containsClaimId: claimId }),
    },
    {
      pollingInterval: 5000,
      skip: hasLoadedClaim,
    },
  )
  const [claim] = fetchedClaims || []
  const [
    submitClaim,
    {
      isSuccess: isClaimSubmitSuccess,
      isError: isSubmitClaimError,
      error: submitClaimError,
      data: updatedClaim,
    },
  ] = useSubmitClaimMutation()

  useEffect(() => {
    if (!isEmpty(claim)) {
      setHasLoadedClaim(true)
    }

    const queryError = listClaimsError || submitClaimError
    if (queryError) {
      const errorMessage = getRTKQueryErrorMessage(queryError)
      customLogger.warn(`[Err: Review Claim Page]: ${errorMessage}`, {
        isListClaimsError,
        isSubmitClaimError,
        claimId,
        sessionId,
        pollSessionId,
      })
      history.push('/error')
    }
  }, [claim, isSubmitClaimError, isListClaimsError, history])

  useEffect(() => {
    const unblock = history.block((_location, action) => {
      if (action === 'POP') {
        customLogger.warn('Back button clicked on Review Claim Page. Refreshing the page')
        history.replace('/my_claims')
        return false
      }
      return undefined
    })

    const unlisten = history.listen((_location, action) => {
      if (action === 'POP') {
        customLogger.warn('Detected URL change due to back button. Refreshing the page')
        history.replace('/my_claims')
      }

      if (action === 'PUSH' || action === 'REPLACE') {
        dispatch(setPollState(''))
      }
    })

    return () => {
      unblock()
      unlisten()
    }
  }, [history])
  const handleModalOpen = (): void => {
    setModalOpen(true)
  }

  const handleModalClose = (): void => {
    setModalOpen(false)
  }

  const handleVerifyClick = (): void => {
    history.push('/profile')
  }

  const isLoading = isSessionLogsLoading || isClaimsLoading || !hasLoadedClaim

  return (
    <ContentLayout>
      <ReviewClaimPageWrapper data-cy="review-claim-page-wrapper">
        <ReviewClaimPageContent>
          {isLoading && (
            <SpinnerLoading data-cy="spinner-loading">
              <Spinner size="md" />
            </SpinnerLoading>
          )}
          {!isEmailVerified && (
            <InlineAlert
              data-cy="no-email-alert"
              color={InlineAlertColor.red}
              primaryButtonProps={{
                onClick: handleVerifyClick,
                text: 'Verify Now',
                'data-cy': 'verify-email-button',
              }}
            >
              To process your claim, let&apos;s verify your email. We&apos;ll never share your
              information or spam you.
            </InlineAlert>
          )}

          {!isPhoneNumberVerified && (
            <InlineAlert
              data-cy="no-phone-number-alert"
              color={InlineAlertColor.red}
              primaryButtonProps={{
                onClick: handleVerifyClick,
                text: 'Verify Now',
                'data-cy': 'verify-phone-button',
              }}
            >
              To process your claim, let&apos;s verify your phone number. We&apos;ll never share
              your information or spam you.
            </InlineAlert>
          )}
          {!isLoading && (
            <>
              <ClaimSummaryHeader data-cy="claim-summary-header">Claim Details</ClaimSummaryHeader>
              <CollapsibleSection>
                <AccordionSection heading="Claim Snapshot" data-cy="claim-snapshot-accordion">
                  <ClaimSnapshot sessionLogs={sessionLogs} />
                </AccordionSection>
              </CollapsibleSection>
              <CollapsibleSection>
                <AccordionSection
                  heading="Shipping Address"
                  data-cy="shipping-address-accordion"
                  isExpanded
                >
                  <CustomerShippingAddress
                    claim={claim as unknown as Claim}
                    handleModalOpen={handleModalOpen}
                    submitClaim={submitClaim}
                    isClaimSubmitSuccess={isClaimSubmitSuccess}
                    isMissingRequiredProfileField={isMissingRequiredProfileField}
                  />
                </AccordionSection>
              </CollapsibleSection>
            </>
          )}
        </ReviewClaimPageContent>
      </ReviewClaimPageWrapper>
      <ClaimResultModal
        claim={(updatedClaim as unknown as Claim) || (claim as unknown as Claim)}
        onDismiss={handleModalClose}
        isModalOpen={isModalOpen}
      />
    </ContentLayout>
  )
}

const ReviewClaimPageWrapper = styled.div({
  [bp.desktop]: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const ReviewClaimPageContent = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    margin: '32px 10px 40px',
  },
  [bp.desktop]: {
    margin: '56px 156px 24px',
    width: '1134px',
  },
})

const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '0 20px',
})

const ClaimSummaryHeader = styled.div({
  fontWeight: 700,
  fontSize: '28px',
  lineHeight: '36px',
})

const CollapsibleSection = styled.div({
  borderRadius: '16px',
  border: `1px solid ${COLOR.NEUTRAL[300]}`,
  display: 'flex',
  padding: '16px',
  flexDirection: 'column',
})

export { ReviewClaimSubmissionPage }

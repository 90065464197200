import * as fromMyExtend from '../store/slices/my-extend'
import * as fromPoll from '../store/slices/poll'
import * as fromChat from './chat/selectors'
import type { RootState } from './index'
import * as fromAuth from './auth/selectors'

export function getAccessToken(state: RootState): ReturnType<typeof fromMyExtend.getAccessToken> {
  return fromMyExtend.getAccessToken(state.myExtend)
}

export function getDecodedAccessToken(
  state: RootState,
): ReturnType<typeof fromMyExtend.getDecodedAccessToken> {
  return fromMyExtend.getDecodedAccessToken(state.myExtend)
}

export function getAuthError(state: RootState): ReturnType<typeof fromAuth.getError> {
  return fromAuth.getError(state.auth)
}

export function getIsUserLoading(state: RootState): ReturnType<typeof fromAuth.getIsLoading> {
  return fromAuth.getIsLoading(state.auth)
}

export const getIsLoggedIn = (state: RootState) => fromMyExtend.isLoggedIn(state.myExtend)

// Consumer Profile
export const getConsumerProfile = (state: RootState) =>
  fromMyExtend.getConsumerProfile(state.myExtend)

// Chat
// ==================================================================================
export const getChatMessages = (state: RootState) => fromChat.getMessages(state.chat)
export const getChatIsLoading = (state: RootState) => fromChat.getIsLoading(state.chat)
export const getChatPrompt = (state: RootState) => fromChat.getPrompt(state.chat)
export const getChatIsPromptHidden = (state: RootState) => fromChat.getIsPromptHidden(state.chat)
export const getChatAccessToken = (state: RootState) => fromChat.getAccessToken(state.chat)
export const getChatLastPromptSlot = (state: RootState) => fromChat.getLastPromptSlot(state.chat)
export const getChatSlots = (state: RootState) => fromChat.getSlots(state.chat)
export const getChatRemainingMessages = (state: RootState) =>
  fromChat.getRemainingMessages(state.chat)
export function getPoll(state: RootState): ReturnType<typeof fromPoll.getPoll> {
  return fromPoll.getPoll(state.poll)
}

// error
export const getHasApiError = (state: RootState) => state.apiError
export const getResourceError = (state: RootState) => state.resourceFetchError

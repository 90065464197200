import React from 'react'
import type { FC } from 'react'
import styled from '@emotion/styled'
import { HeadTag } from '../head-tag'

import { bp } from '../../utils/breakpoints'

export interface ContentLayoutProps {
  className?: string
  pageTitle?: string
  'data-cy'?: string
}

const ContentLayout: FC<ContentLayoutProps> = ({
  children,
  className,
  'data-cy': dataCy,
  pageTitle = 'Extend',
}) => {
  return (
    <StyledContentLayout className={className} data-cy={dataCy}>
      <HeadTag title={pageTitle} />
      {children}
    </StyledContentLayout>
  )
}

const StyledContentLayout = styled.div({
  [bp.mobile]: {
    padding: '48px 0 24px',
  },
  [bp.desktop]: {
    padding: '72px 0 24px',
  },
})

export { ContentLayout }

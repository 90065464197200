import React from 'react'

const BackArrow: React.FC = () => {
  return (
    <svg width="24px" height="60px" viewBox="0 0 24 60" version="1.1">
      <g
        id="Claims-portal"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        opacity="0.5"
      >
        <g
          id="Desktop_Product-picker-"
          transform="translate(-281.000000, -636.000000)"
          fill="#000000"
        >
          <polygon
            id="back_button"
            points="304.092041 636 281 666 304.092041 696 305 694.7179 282.887348 665.999823 305 637.281746"
          />
        </g>
      </g>
    </svg>
  )
}

export default BackArrow

import type { SagaIterator } from 'redux-saga'
import { put } from '@redux-saga/core/effects'
import { chatActions } from '../../actions'
import { generateBotFailureMessage, generateContactPageRedirectPrompt } from '../../lib/chat-utils'

/**
 * Maps all image-upload related errors  in a custom chat-message.
 * Network failures, CORS, and/or uncaught errors rooted into the presigned POST request
 * does not return a parsable "Reply" interface, and so we have to generate a canned response
 * to relay the failure to the end-user
 */
export const ERROR_MESSAGE = [
  'Uh oh, fill out our support form and someone will be in touch with you right away to help. ' +
    'You can also call one of our awesome Customer Care Specialists at (877) 248-7707. ' +
    'I’m so sorry for the hassle!',
].join('')

export function* setImageUploadFailureMessage(messageKey: string): SagaIterator {
  yield put(
    chatActions.chatMessagesUpdateMessage(generateBotFailureMessage(ERROR_MESSAGE), messageKey),
  )
  yield put(chatActions.chatPromptSet(generateContactPageRedirectPrompt()))
}

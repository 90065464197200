import type { FC } from 'react'
import React from 'react'

export const StolenPackage: FC = () => {
  return (
    <svg
      width="113"
      height="97"
      viewBox="0 0 113 97"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M49.9438 83.9952L44.5 85.5498V3.45023L83.0667 14.4684V32.95C83.0667 33.7688 83.7312 34.4333 84.55 34.4333C85.3688 34.4333 86.0333 33.7688 86.0333 32.95V13.35C86.0333 12.6884 85.5943 12.107 84.9564 11.923L43.4231 0.0563667C43.3816 0.0445 43.3371 0.0652667 43.2955 0.0563667C43.2036 0.0385667 43.1146 0 43.0167 0C42.9188 0 42.8298 0.0385667 42.7378 0.0563667C42.6933 0.0652667 42.6488 0.0445 42.6073 0.0563667L1.07393 11.923C0.439067 12.107 0 12.6884 0 13.35V75.65C0 76.3116 0.439067 76.893 1.07393 77.077L42.6073 88.9436C42.7408 88.9822 42.8802 89 43.0167 89C43.1531 89 43.2926 88.9822 43.4231 88.9466L50.7597 86.8492C51.5488 86.6237 52.0027 85.8019 51.7772 85.0158C51.5518 84.2296 50.7419 83.7727 49.9438 83.9952ZM14.8333 11.0775L26.697 7.68663V42.3996L25.2137 43.8829L21.8139 40.4831C21.2354 39.9046 20.295 39.9046 19.7165 40.4831L16.3167 43.8859L14.8333 42.4026V11.0775ZM41.5333 85.5498L2.96667 74.5316V14.4684L11.8667 11.926V43.0167C11.8667 43.4112 12.0239 43.788 12.3028 44.0639L15.2694 47.0306C15.8479 47.6091 16.7884 47.6091 17.3669 47.0306L20.7667 43.6308L24.1665 47.0335C24.4572 47.3213 24.8369 47.4667 25.2167 47.4667C25.5964 47.4667 25.9761 47.3213 26.2639 47.0335L29.2306 44.0669C29.5065 43.788 29.6637 43.4112 29.6637 43.0167V6.84113L41.5333 3.45023V85.5498Z"
        fill="#2146DE"
      />
      <path
        d="M112 97H54C53.4 97 53 96.6 53 96V93.6C53 86.4 57.4 79.6 63.6 77.4L70 75.2C70.4 75 70.8 75.2 71 75.4C74.2 78.8 78.2 80.8 82.6 80.8C87 80.8 91 78.8 94.2 75.4C94.4 75.2 94.8 75 95.2 75.2L101.6 77.4C107.8 79.6 113 87 113 94V95.8C113 96.6 112.6 97 112 97ZM55 95H111V94.2C111 88 106.6 81.4 101 79.4L95.2 77.4C91.6 81 87.2 83 82.6 83C78 83 73.6 81 70 77.4L64.2 79.4C59 81.2 55 87.4 55 93.6V95Z"
        fill="#27AEE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M54.3044 90.9131C54.3044 90.4329 54.6937 90.0436 55.1739 90.0436H110.826C111.306 90.0436 111.696 90.4329 111.696 90.9131C111.696 91.3934 111.306 91.7827 110.826 91.7827H55.1739C54.6937 91.7827 54.3044 91.3934 54.3044 90.9131Z"
        fill="#27AEE4"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M55.6087 85.6957C55.6087 85.2155 55.998 84.8262 56.4783 84.8262H108.652C109.132 84.8262 109.522 85.2155 109.522 85.6957C109.522 86.176 109.132 86.5653 108.652 86.5653H56.4783C55.998 86.5653 55.6087 86.176 55.6087 85.6957Z"
        fill="#27AEE4"
      />
      <path
        d="M78 59H76C75.4 59 75 58.6 75 58C75 57.4 75.4 57 76 57H78C78.6 57 79 57.4 79 58C79 58.6 78.6 59 78 59Z"
        fill="#27AEE4"
      />
      <path
        d="M90 59H88C87.4 59 87 58.6 87 58C87 57.4 87.4 57 88 57H90C90.6 57 91 57.4 91 58C91 58.6 90.6 59 90 59Z"
        fill="#27AEE4"
      />
      <path
        d="M83 79C76.6 79 70.8 74.2 68.6 67C66.6 67 65 64.4 65 61C65 57.8 66.2 55.4 68 55C68.2 55 68.2 54.8 68.4 54.6C69 54.4 69.6 54.4 70 55C70.4 55.4 70.2 56 69.8 56.4C69.4 56.6 69.4 56.6 69.2 56.6C69 56.8 68.8 57 68.6 57C68 57 67 58.6 67 61C67 63.4 68 65 68.6 65C68.6 65 68.6 65 68.8 65C69 64.8 69.4 64.8 69.8 65C70 65.2 70.4 65.4 70.4 65.6C71.8 72.2 77.2 77 83 77C88.8 77 94.2 72.2 95.8 65.6C95.8 65.2 96 65 96.4 65C96.6 64.8 97 65 97.4 65C97.6 65 97.6 65 97.6 65C98.2 65 99.2 63.4 99.2 61C99.2 58.6 98.2 57 97.6 57C97 57 96.6 56.6 96.6 56C96.6 55.4 97 55 97.6 55C99.6 55 101.2 57.6 101.2 61C101.2 64.4 99.6 67 97.6 67C95.2 74.2 89.4 79 83 79Z"
        fill="#27AEE4"
      />
      <path
        d="M68 57H67.8C66.8 56.6 65 55.6 65 53C65 50.8 66.6 49.8 68 49.2C68.6 43.2 74.6 37 83 37C91.4 37 97.4 43.2 98 49.2C99.4 49.8 101 50.8 101 53C101 55.6 99.2 56.6 98.2 57C98 57 97.6 57 97.4 57C97.4 57 90.4 53.2 82.8 53.2C75.2 53.2 68.4 57 68.2 57C68.4 57 68.2 57 68 57ZM83 51C90 51 96.4 54 98 54.8C98.4 54.6 99 54.2 99 53C99 51.4 96.8 51 96.8 51C96.4 51 96 50.4 96 50C96 45.6 91.4 39 83 39C74.6 39 70 45.6 70 50C70 50.4 69.6 50.8 69.2 51C69.2 51 67 51.4 67 53C67 54.2 67.6 54.6 68 54.8C69.6 54 76 51 83 51Z"
        fill="#27AEE4"
      />
      <path
        d="M73 51C72.6 51 72.2 50.8 72.2 50.4C72 50 72.2 49.4 72.6 49C72.8 49 76.6 46.8 82 46.8C82.6 47 83 47.4 83 48C83 48.6 82.6 49 82 49C77 49 73.6 50.8 73.4 50.8C73.4 51 73.2 51 73 51Z"
        fill="#27AEE4"
      />
      <path
        d="M88 67C85.2 67 84 66.4 83.2 65H82.6C82 66.4 80.6 67 77.8 67C71.6 67 66.6 61.2 65.8 57.2C66 56.6 66.2 56.2 66.8 56C67.4 55.8 67.8 56.2 68 56.8C68.6 60.2 73 65 78 65C80.8 65 80.8 64.6 81 63.8C81.2 63.4 81.6 63 82 63H84C84.4 63 84.8 63.4 85 63.8C85.2 64.6 85.4 65 88 65C93 65 96.4 60.2 97 56.8C97.2 56.2 97.6 56 98.2 56C98.8 56.2 99 56.6 99 57.2C98.2 61.2 94.2 67 88 67Z"
        fill="#27AEE4"
      />
    </svg>
  )
}

import type { FC } from 'react'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'

import { logEvent } from '../analytics'
import { useTheme } from '@emotion/react'

export const ClaimApproveWheelTireDetails: FC = () => {
  const history = useHistory()
  const theme = useTheme()

  const handleClick = (): void => {
    logEvent('Claims Details - Clicks', 'Contact Support')
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      history.push('/contact')
    }
  }

  return (
    <DetailsWrapper>
      Sit tight, one of our customer care specialists will be in touch with you shortly via email to
      fulfill your claim. <br />
      <br />
      Until then, if you have any questions please feel free to{' '}
      <StyledLink to="/contact" onClick={handleClick}>
        contact support
      </StyledLink>{' '}
      or call <NoWrap>(877) 248-7707.</NoWrap>
    </DetailsWrapper>
  )
}

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  cursor: 'pointer',
})

const DetailsWrapper = styled.div({
  [bp.lg]: {
    width: 400,
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.md]: {
    width: 270,
  },
  fontSize: 15,
  lineHeight: '20px',
  width: 307,
  height: '100%',
})

const NoWrap = styled.div({
  whitespace: 'nowrap',
})

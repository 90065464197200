import { createApi } from '@reduxjs/toolkit/query/react'
import type {
  Contract,
  ContractsGetResponse,
  ContractsV2GetResponse,
  ContractsUpdateResponse,
  Contract20220201GetResponse,
} from '@customers-api-client'
import {
  mapContractCoverage,
  mapContractsGetToContract,
  mapContractsGet20220201ToContract,
} from './utils'
import { baseQuery, X_EXTEND_ACCESS_TOKEN } from '../base-query'
import type {
  ContractSearchV1QueryStringOptions,
  ContractsSearchV1Response,
  UpdateContractRequest,
  GetContractParams,
} from './types'
import { safeBtoa } from '../../helpers'

const buildCacheKeyV1 = (qs: ContractSearchV1QueryStringOptions): string => {
  return safeBtoa(JSON.stringify(qs))
}

export const contractsApi = createApi({
  baseQuery,
  reducerPath: 'Contracts',
  tagTypes: ['Contracts'],
  endpoints: (build) => ({
    getContract: build.query<Contract, GetContractParams>({
      query: ({ contractId, apiVersion = 'latest', accessToken }) => ({
        url: `contracts/${contractId}`,
        headers: {
          accept: `application/json; version=${apiVersion};`,
          ...(accessToken && { [X_EXTEND_ACCESS_TOKEN]: accessToken }),
        },
      }),
      providesTags: (_, _err, { contractId }) => [{ type: 'Contracts', id: contractId }],
      transformResponse: (
        contract: ContractsGetResponse | ContractsV2GetResponse | Contract20220201GetResponse,
        _meta,
        { apiVersion },
      ) => {
        if (apiVersion === '2022-02-01') {
          return mapContractCoverage(
            mapContractsGet20220201ToContract(contract as Contract20220201GetResponse),
          )
        }
        const hasOrderId = 'orderId' in contract
        const contractWithVersion = hasOrderId
          ? ({
              ...contract,
              contractVersion: '2.0',
            } as ContractsV2GetResponse)
          : ({
              ...contract,
              contractVersion: '1.0',
            } as ContractsGetResponse)

        return mapContractCoverage(mapContractsGetToContract(contractWithVersion))
      },
    }),
    searchContractsV1: build.query<ContractsSearchV1Response, ContractSearchV1QueryStringOptions>({
      query: ({ apiVersion = 'latest', ...qs }) => ({
        url: 'contracts/search',
        params: {
          ...qs,
          typeFilter: qs.typeFilter?.join(',') ?? 'pcrs,shipping_protection',
        },
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      providesTags: (_, _err, qs) => {
        return [{ type: 'Contracts', id: buildCacheKeyV1(qs) }]
      },
    }),
    updateContract: build.mutation<ContractsUpdateResponse, UpdateContractRequest>({
      query: ({ apiVersion = 'latest', contractId, data }: UpdateContractRequest) => ({
        url: `contracts/${contractId}`,
        method: 'PUT',
        body: data,
        headers: {
          'content-type': 'application/json',
          accept: `application/json; version=${apiVersion};`,
        },
      }),
      invalidatesTags: ['Contracts'],
    }),
  }),
})

export const { useGetContractQuery, useSearchContractsV1Query, useUpdateContractMutation } =
  contractsApi

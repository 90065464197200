import React from 'react'
import type { FC } from 'react'
import { ContentLayout } from '@customers-ui'
import { useContactMutation } from '@customers-api-rtk-query'
import type { ContactRequest } from '@customers-api-rtk-query'
import { ContactForm } from './contact-form'
import { ContactFormSuccess } from './contact-form-success'

const Contact: FC = () => {
  const [contact, { isLoading, isSuccess, isError }] = useContactMutation()
  const onSubmit = (formValues: ContactRequest) => {
    contact(formValues)
  }

  return (
    <ContentLayout pageTitle="Contact Extend">
      {isSuccess ? (
        <ContactFormSuccess />
      ) : (
        <ContactForm onSubmit={onSubmit} isLoading={isLoading} isError={isError} />
      )}
    </ContentLayout>
  )
}

export { Contact }

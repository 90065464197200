import { ContractsSearchIndividual } from '../types/contract'
import { EntitlementSearchByOrdersResponse, EmsProduct } from '@customers-api-rtk-query'

// This function currently filters & only maps shipping_protection or shipping_resolution entitlements
export const sortAndGroupEMSData = (
  emsData: EntitlementSearchByOrdersResponse[],
): Partial<Record<ContractsSearchIndividual['type'], Array<EntitlementSearchByOrdersResponse>>> => {
  // maintain the existing order by contract type with priority given to shipping protection
  const groupedEmsData: Partial<
    Record<ContractsSearchIndividual['type'], Array<EntitlementSearchByOrdersResponse>>
  > = {
    shipping_protection: [],
  }

  emsData.forEach((data) => {
    const hasShippingProtection = data.products.some((product) =>
      product.entitlements?.some(
        (entitlement) =>
          entitlement.type === 'shipping_protection' || entitlement.type === 'shipping_resolution',
      ),
    )
    if (hasShippingProtection) {
      groupedEmsData.shipping_protection!.push(data)
    }
  })

  Object.entries(groupedEmsData).forEach(([type, data]) => {
    data.sort((a: EntitlementSearchByOrdersResponse, b: EntitlementSearchByOrdersResponse) => {
      if (type === 'shipping_protection') {
        if (isShippingEntitlementExpired(a.products) && !isShippingEntitlementExpired(b.products)) {
          return 1
        }

        if (!isShippingEntitlementExpired(a.products) && isShippingEntitlementExpired(b.products)) {
          return -1
        }
      }

      const aPurchaseDate = a.products[0].transactionDate as number
      const bPurchaseDate = b.products[0].transactionDate as number

      if (aPurchaseDate && bPurchaseDate) {
        return new Date(bPurchaseDate).getTime() - new Date(aPurchaseDate).getTime()
      }

      return 0
    })
  })

  return groupedEmsData
}

const isShippingEntitlementExpired = (emsProducts: EmsProduct[]): boolean => {
  const today = new Date()
  return emsProducts.every((product) => {
    // shipping resolution entitlements are considered expired if the entitlement status is 'expired'
    const shippingResolutionEntitlement = product.entitlements?.find(
      (product) => product.type === 'shipping_resolution',
    )
    if (shippingResolutionEntitlement) {
      return shippingResolutionEntitlement.status === 'expired'
    }

    if (!product.tracking) return false
    if (!product.tracking?.actualDeliveryDate) return false
    const expirationDate = new Date(product.tracking?.actualDeliveryDate).setDate(
      new Date(product.tracking?.actualDeliveryDate).getDate() + 60,
    )
    return today.getTime() > expirationDate
  })
}

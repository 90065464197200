import { EXTEND_ENV } from '@helloextend/client-constants'
import type { FC } from 'react'
import React from 'react'
import { connect } from 'react-redux'
import { COLOR, ContentLayout, Spinner, bp } from '@customers-ui'
import {
  useListInsuranceClaimsQuery,
  getRTKQueryErrorMessage,
  useListOrdersQuery,
} from '@customers-api-rtk-query'
import styled from '@emotion/styled'
import type { RootState } from '../../reducers'
import * as selectors from '../../reducers/selectors'
import { ErrorComponent } from './error-component'
import type { Claim } from '../../types/claim'
import { isEmpty } from 'lodash'
import { mapToClaimDetails } from './utils'
import { ViewAllClaims } from './view-all-claims'
import { EmptyPageContent } from '../plans-page/empty-page-content'

interface ClaimsPageProps {
  decodedAccessToken: ReturnType<typeof selectors.getDecodedAccessToken>
}

const Component: FC<ClaimsPageProps> = ({ decodedAccessToken }) => {
  const email = decodedAccessToken?.email
  const phone = decodedAccessToken?.phone_number

  const {
    data: { items: fetchedClaims } = {},
    isLoading: isLoadingClaims,
    error: claimsQueryError,
  } = useListInsuranceClaimsQuery({
    containsCustomerEmail: email,
    containsCustomerPhone: phone,
    minLimit: 500,
  })

  // Accounts in lower environments can potentially have hundreds of claims, and each claim requires a request to get service orders
  // and a request to get merchant servicing settings. This will limit those accounts to 10 displayed claims.
  let claims = fetchedClaims

  if (EXTEND_ENV !== 'production' && fetchedClaims) {
    claims = fetchedClaims.slice(0, 10)
  }

  const {
    data: emsOrdersResponse,
    isLoading: isLoadingEmsData,
    error: listOrdersQueryError,
  } = useListOrdersQuery({})

  const claimDetails = mapToClaimDetails(
    (claims as unknown as Claim[]) ?? [],
    emsOrdersResponse ?? [],
  )
  const hasAtLeastOneMatchingOrder = Object.values(claimDetails).some(
    (claimDetail) => claimDetail.emsData,
  )

  return (
    <ContentLayout pageTitle="My Claims">
      <ErrorComponent
        claimsError={claimsQueryError && getRTKQueryErrorMessage(claimsQueryError)}
        emsError={listOrdersQueryError && getRTKQueryErrorMessage(listOrdersQueryError)}
      />
      {isLoadingClaims || isLoadingEmsData ? (
        <SpinnerLoading data-cy="spinner-loading">
          <Spinner size="md" />
        </SpinnerLoading>
      ) : (
        <>
          {isEmpty(claims) || !hasAtLeastOneMatchingOrder ? (
            <EmptyPageWrapper>
              <EmptyPage>
                <EmptyPageHeader data-cy="claims-page-header">Claims</EmptyPageHeader>
                <EmptyPageContainer>
                  <EmptyPageContent
                    primaryButtonText="View My Orders"
                    primaryButtonUrl="/my_plans"
                    headerText="No claims found."
                  >
                    To start a claim, go to the <b>My Orders</b> tab and select the order you need
                    assistance with.
                  </EmptyPageContent>
                </EmptyPageContainer>
              </EmptyPage>
            </EmptyPageWrapper>
          ) : (
            <>
              <ClaimsPageSubheader data-cy="claims-page-subheader">
                Stay Updated on Your Claims
              </ClaimsPageSubheader>
              <ClaimsPageHeader data-cy="claims-page-header">Claims</ClaimsPageHeader>
              <ViewAllClaims claimDetails={claimDetails} />
            </>
          )}
        </>
      )}
    </ContentLayout>
  )
}

const ClaimsPageHeader = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  textAlign: 'end',
  fontWeight: 800,
  color: COLOR.NEUTRAL[1000],
  lineHeight: 'auto',
  [bp.mobile]: {
    margin: '0px 20px 0px 36px',
    fontSize: 28,
    fontWeight: 700,
  },
  [bp.tablet]: {
    margin: '0px 80px 0px 80px',
    fontSize: 40,
  },
  [bp.desktop]: {
    margin: '0px 120px 24px 255px',
    fontSize: 44,
  },
})

const EmptyPageWrapper = styled.div({
  [bp.desktop]: {
    display: 'flex',
    justifyContent: 'center',
  },
})

const EmptyPageHeader = styled.div({
  fontWeight: 800,
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-start',
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    fontSize: 28,
  },
  [bp.tablet]: {
    fontSize: 40,
  },
  [bp.desktop]: {
    fontSize: 44,
  },
})

const ClaimsPageSubheader = styled.div({
  color: COLOR.NEUTRAL[900],
  fontFamily: 'Nunito Sans',
  fontWeight: 400,
  lineHeight: 'auto',
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'flex-start',
  textAlign: 'end',
  [bp.mobile]: {
    margin: '24px 20px 0px 36px',
    fontSize: 15,
  },
  [bp.tablet]: {
    margin: '24px 80px 0px 80px',
    fontSize: 15,
  },
  [bp.desktop]: {
    margin: '56px 120px 0px 255px',
    fontSize: 20,
  },
})

export const SpinnerLoading = styled.div({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: '50px 20px 0',
})

export const EmptyPage = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  [bp.mobile]: {
    margin: '32px 10px 40px',
  },
  [bp.desktop]: {
    margin: '56px 156px 24px',
    width: '1134px',
  },
})

export const EmptyPageContainer = styled.div({
  alignContent: 'center',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: '100%',
})

const ClaimsPage = connect((state: RootState): ClaimsPageProps => {
  return {
    decodedAccessToken: selectors.getDecodedAccessToken(state),
  }
})(Component)

export type { ClaimsPageProps }
export { Component, ClaimsPage }

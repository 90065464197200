import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { getItem, setItem } from '../persistence'

export interface PollState {
  [key: string]: string | undefined
}

export const initialState: PollState = {}

const pollSlice = createSlice({
  name: 'poll',
  initialState,
  reducers: {
    setPollState(state, action: PayloadAction<string>) {
      setItem('poll', action.payload)
      return {
        ...state,
        poll: action.payload,
      }
    },
  },
})

const getPoll = (state: PollState): PollState => {
  if (state.poll) {
    return JSON.parse(state.poll) as PollState
  }

  return typeof getItem('poll') === 'string'
    ? (JSON.parse(getItem('poll') as string) as PollState)
    : {}
}

const pollReducer = pollSlice.reducer
const { setPollState } = pollSlice.actions
export { pollReducer, setPollState, getPoll }

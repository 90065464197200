import type { SagaIterator } from 'redux-saga'
import { customLogger } from '@extend/client-helpers'
import { call, put } from '@redux-saga/core/effects'
import { client, ClaimSource } from '@customers-api-client'
import { getTokenSub } from '../../lib/security-utils'
import { isErrorResponse, isExceptionResponse } from '@customers-api-fetch'
import { chatActions } from '../../actions'
import { generateBotMessage } from '../../lib/chat-utils'
import cancelableLoadBotContent from './cancelable-load-bot-content'

const filedBy = {
  firstName: 'Kaley',
  lastName: 'Chatbot',
}
export default function* sessionStart(
  action: ReturnType<typeof chatActions.chatSessionRestart>,
): SagaIterator {
  let sessionId = ''
  const { contractId, orderId, lineItemIds, shipmentId, accessToken } = action.payload || {}

  yield put(chatActions.chatSessionConnectRequest())
  try {
    // When connecting from contract-details-page emulate the update session saga
    const firstBotMessage = generateBotMessage()

    yield put(chatActions.chatMessagesAdd(firstBotMessage))
    const startRequestTime = new Date().getTime()
    const response = yield call(() =>
      client.incredibot.connect({
        data: {
          source: ClaimSource.chatbot,
          filedBy,
          contractId,
          orderId,
          lineItemIds,
          shipmentId,
        },
        accessToken: accessToken || '',
      }),
    )

    const endRequestTime = new Date().getTime()
    const requestTimeout = endRequestTime - startRequestTime

    // Error handling for loading the first message
    if (isErrorResponse(response)) {
      yield put(chatActions.chatSessionConnectFailure(response.data.message, response.status))
      return
    }

    if (isExceptionResponse(response)) {
      yield put(chatActions.chatSessionConnectFailure('An error occurred', response.status))
      return
    }

    const { data: reply } = response

    sessionId = getTokenSub(reply.accessToken)

    customLogger.info(`Successfully called /connect, sessionId: ${sessionId}`, {
      sessionId,
      timestamp: Date.now(),
    })

    yield put(chatActions.chatSessionConnectSuccess(reply))

    // For the content loading so it can be cancelled
    if (contractId || orderId || lineItemIds) {
      // Specifically for loading bot content when routing from contract-details-page
      yield call(
        cancelableLoadBotContent,
        reply,
        reply.prompt.slot,
        firstBotMessage.key,
        requestTimeout,
      )
    } else {
      yield call(cancelableLoadBotContent, reply, reply.prompt?.slot)
    }
  } catch (e) {
    if (e instanceof Error) {
      customLogger.warn(`Error calling /connect, sessionId: ${sessionId}`, {
        sessionId,
        timestamp: Date.now(),
        err: e.message,
      })
      yield put(chatActions.chatSessionConnectFailure(e.message))
    }
  }
}

import type { FC } from 'react'
import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import { bp, COLOR, Grid, Button } from '@customers-ui'

import { images } from '../../lib/assets'

const ContractNotFound: FC = () => {
  const history = useHistory()

  const routeToPlans = (): void => {
    history.push('/my_plans')
  }

  const routeToSupport = (): void => {
    history.push('/contact')
  }

  return (
    <NoContractWrapper>
      <TitleWrapper>
        <ChatHead />
        <Title>Contract not found :(</Title>
      </TitleWrapper>
      <NoContractMessage>
        I can&apos;t seem to find the page you&apos;re looking for. Please check your URL and try
        again.
      </NoContractMessage>
      <ButtonGrid>
        <StyledButton kind="primary" text="Go back to My Plans" onClick={routeToPlans} />
        <StyledButton kind="secondary" text="Contact support" onClick={routeToSupport} />
      </ButtonGrid>
    </NoContractWrapper>
  )
}

const NoContractWrapper = styled(Grid)({
  flexDirection: 'column',
  maxWidth: 616,
  [bp.mobile]: {
    margin: '56px 20px 0',
  },
  [bp.desktop]: {
    margin: '0 auto',
    marginTop: 'calc(50vh - 178px)',
  },
})

const TitleWrapper = styled(Grid)({
  [bp.mobile]: {
    marginBottom: 16,
  },
  [bp.desktop]: {
    marginBottom: 18,
  },
})

const ChatHead = styled.div({
  alignSelf: 'center',
  width: 52,
  height: 52,
  backgroundImage: `url(${images.kaleyLarge})`,
  backgroundSize: 'contain',
  backgroundColor: COLOR.BLUE[300],
  borderRadius: '50%',
})

const Title = styled.div({
  alignSelf: 'center',
  color: COLOR.NEUTRAL[1000],
  [bp.mobile]: {
    marginLeft: 8,
    fontSize: 24,
    lineHeight: '28px',
  },
  [bp.desktop]: {
    marginLeft: 16,
    fontSize: 36,
    lineHeight: '44px',
  },
})

const NoContractMessage = styled.div({
  marginBottom: 24,
  color: COLOR.NEUTRAL[800],
  [bp.mobile]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  [bp.desktop]: {
    fontSize: 20,
    lineHeight: '28px',
  },
})

const ButtonGrid = styled(Grid)({
  gap: 16,
  [bp.mobile]: {
    flexDirection: 'column',
  },
  [bp.desktop]: {
    flexDirection: 'row',
  },
})

const StyledButton = styled(Button)({
  width: 'fit-content',
  [bp.mobile]: {
    height: 38,
    borderRadius: 20,
    fontSize: 16,
    padding: '8px 24px',
  },
  [bp.desktop]: {
    height: 47,
    borderRadius: 24,
    fontSize: 20,
    padding: '10px 48px',
  },
})

export { ContractNotFound }

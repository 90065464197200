import { useState, useEffect } from 'react'
import type { Content, Plan, PlanDetails } from '@customers-api-client'
import type { useLazyGetContentQuery } from '@customers-api-rtk-query'
import { customLogger } from '@extend/client-helpers'
import { defaultsDeep } from 'lodash/fp'
import { isArray } from 'lodash'

export interface UseLazyGetContentQueryState {
  contentQueryData: Content | undefined
  isFetching: boolean
  isLoading: boolean
  isError: boolean
}

export interface UsePlanDetailsDataProps {
  isLoading: boolean
  isErrored: boolean
  storeId: string
  plan: Plan | undefined
  locale: string
  planDetailsQueryData: PlanDetails | undefined
  isPlanDetailsCCSFetchingEnabled: boolean
  getContentFn: ReturnType<typeof useLazyGetContentQuery>[0]
  getContentState: UseLazyGetContentQueryState
  isLazyPlanDetailsFetch?: boolean
}

export interface UsePlanDetailsDataReturnType {
  planDetails: PlanDetails | undefined
  isLoading: boolean
  isErrored: boolean
}

export function usePlanDetailsData({
  isErrored,
  isLoading,
  storeId,
  plan,
  locale,
  isPlanDetailsCCSFetchingEnabled,
  getContentFn,
  getContentState,
  planDetailsQueryData,
  // If the plan details are pre-fetched, set this to false
  // This is necessary because the warranty terms page doesn't
  // lazy-load plan details like the plan details page. In this case
  // we want to set the default state to the already-loaded details
  // and overwrite with CCS if enabled
  isLazyPlanDetailsFetch = true,
}: UsePlanDetailsDataProps): UsePlanDetailsDataReturnType {
  const {
    isLoading: isLoadingContent,
    isFetching: isFetchingContent,
    isError: isErrorContent,
  } = getContentState
  const hookDataIsLoading = isLoading || isLoadingContent || isFetchingContent
  const hookDataErrored = isErrored || isErrorContent

  const [planDetails, setPlanDetails] = useState<PlanDetails | undefined>(
    isLazyPlanDetailsFetch ? undefined : planDetailsQueryData,
  )
  /**
   * Conditionally fetch plan details data from /content/offers hook
   */
  useEffect(() => {
    if (plan && isPlanDetailsCCSFetchingEnabled) {
      const {
        contract: { service_type: serviceType, coverage_includes: coverageType },
        product_types: productCategory,
      } = plan

      // CCS query function executed lazily
      getContentFn({
        storeId: storeId || '',
        serviceType,
        coverageType,
        productCategory,
        locale,
      })
    }
  }, [plan, storeId, locale, isPlanDetailsCCSFetchingEnabled, getContentFn])

  useEffect(() => {
    // if we are still loading the plan details or content offers query,
    // exit hook
    if (hookDataIsLoading) {
      return
    }

    // exit if any query threw an error
    if (hookDataErrored) {
      customLogger.warn('Error loading query')
      return
    }

    // If we received a response from /content/offers, extract and put into state
    if (planDetailsQueryData || getContentState.contentQueryData || !hookDataIsLoading) {
      if (getContentState.contentQueryData && isArray(getContentState.contentQueryData.results)) {
        const { payloads } = getContentState.contentQueryData.results[0]
        const customPlanDetails = {
          planDetails: {
            ...payloads?.planDetails,
            ...payloads?.planDetailsCalculator,
          },
          planTerms: payloads?.planTerms,
          planTermsLabels: payloads?.planTermsLabels,
          common: payloads?.common,
          contractPurchaseEmail: payloads?.contractPurchaseEmail,
        }

        const planDetailsConcat: PlanDetails = defaultsDeep(planDetailsQueryData, customPlanDetails)

        setPlanDetails(planDetailsConcat)
      }
    }
  }, [getContentState.contentQueryData, planDetailsQueryData, hookDataIsLoading, hookDataErrored])

  return { planDetails, isLoading: hookDataIsLoading, isErrored: hookDataErrored }
}

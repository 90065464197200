import type { FC } from 'react'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import styled from '@emotion/styled'
import type { ServiceOrder } from '@customers-api-client'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'

import { logEvent } from '../analytics'
import { useTheme } from '@emotion/react'

export interface ClaimApproveDetailsProps {
  serviceOrder?: ServiceOrder[]
  isRepair: boolean
}

export const ClaimApproveDetails: FC<ClaimApproveDetailsProps> = ({ serviceOrder, isRepair }) => {
  const history = useHistory()
  const theme = useTheme()
  if (!serviceOrder || !serviceOrder?.length) {
    return null
  }

  if (!isRepair && !serviceOrder[0].shippingLabels) {
    return null
  }

  const handleClick = (): void => {
    logEvent('Claims Details - Clicks', 'Get My Shipping Label')
    if (serviceOrder?.length && serviceOrder[0].shippingLabels?.length) {
      const image = serviceOrder[0].shippingLabels[0].imageData
      const w = window.open('')
      const img = new Image()
      img.src = `data:image/png;base64,${image}`
      w?.document.write(img.outerHTML)
      setTimeout(() => {
        w?.print()
        w?.close()
      }, 250)
    }
  }
  const handleContactSupportClick = (): void => {
    logEvent('Claims Details - Clicks', 'Contact Support')
    if (theme.merchantConfiguration.merchantSupportUrl) {
      window.open(theme.merchantConfiguration.merchantSupportUrl, '_blank')
    } else {
      history.push('/contact')
    }
  }

  const isCheckedIn = serviceOrder?.[0]?.status === 'checked_in'
  const isRepairStarted = serviceOrder?.[0]?.status === 'repair_started'

  if (isRepair && (isCheckedIn || isRepairStarted)) {
    return (
      <DetailsWrapper>
        Sit tight, your repair is currently in progress. We’ll send you updates along the process.
      </DetailsWrapper>
    )
  }

  /*
    Previously this detail wrapper displayed for !isRepair, but this shows for both repairs and replacements now.
    When serviceOrder is status "accepted", "pending - ship product" is displayed
  */

  if (serviceOrder?.[0]?.status === 'accepted') {
    return (
      <DetailsWrapper>
        Your claim has been approved! It’s time to ship back your defective product. Follow the
        shipping instructions on your email to continue the replacement process.
        <Button onClick={handleClick}>Get my shipping label</Button>
      </DetailsWrapper>
    )
  }

  return (
    <DetailsWrapper>
      Sit tight, one of our servicer specialists will be in touch with you shortly via email to
      start the repair process.
      <br />
      <br />
      Until then, if you have any questions please feel free to
      <StyledLink to="/contact" onClick={handleContactSupportClick}>
        {' '}
        contact support
      </StyledLink>{' '}
      or call <NoWrap>(877) 248-7707.</NoWrap>
    </DetailsWrapper>
  )
}

const Button = styled.button({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  background: COLOR.BLUE[800],
  borderRadius: 24,
  width: 211,
  height: 38,
  [bp.lg]: {
    height: 47,
    width: 300,
    fontSize: 20,
    lineHeight: '27px',
    margin: '20px 0',
  },
  color: COLOR.WHITE,
  fontSize: 16,
  lineHeight: '22px',
  fontWeight: 'bold',
  margin: '10px 0 10px',
})

const StyledLink = styled(Link)({
  color: COLOR.BLUE[800],
  '&:visited': {
    color: COLOR.BLUE[800],
  },
  cursor: 'pointer',
})

const NoWrap = styled.div({
  whitespace: 'nowrap',
})

const DetailsWrapper = styled.div({
  [bp.md]: {
    width: 400,
    margin: '10px 0 30px',
  },
  [bp.lg]: {
    fontSize: 16,
    lineHeight: '24px',
  },
  fontSize: 15,
  lineHeight: '20px',
  width: 307,
  height: '100%',
})

import { fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import { EXTEND_API_HOST, EXTEND_ENV, EXTEND_APP_NAME } from '@helloextend/client-constants'
import type { MyExtendState, RootState } from './types/state'

const ACCESS_TOKEN_KEY = `${EXTEND_ENV}_MERCHANTS_ACCESS_TOKEN_V2`
const EXTEND_ACCESS_TOKEN_KEY = `${EXTEND_ENV}_${EXTEND_APP_NAME}_ACCESS_TOKEN`
export const X_EXTEND_ACCESS_TOKEN = 'X-Extend-Access-Token'
export const baseQuery = fetchBaseQuery({
  baseUrl: `https://${EXTEND_API_HOST}`,
  prepareHeaders: (headers, { getState }) => {
    const accessToken =
      (getState() as RootState)?.auth?.accessToken ||
      (getState() as MyExtendState)?.myExtend?.accessToken

    /**
     * If client isn't using redux, grab the value from localStorage
     */
    const token = accessToken || safeFetchStoredToken() || safeFetchExtendOktaLoginToken()
    // allow increditbot to set its own header
    if (token && !headers.has(X_EXTEND_ACCESS_TOKEN)) {
      headers.set(X_EXTEND_ACCESS_TOKEN, `${token}`)
    }

    return headers
  },
})

function safeFetchStoredToken(): string {
  try {
    const rawToken = localStorage.getItem(ACCESS_TOKEN_KEY)
    return rawToken ? JSON.parse(rawToken) : ''
  } catch (err) {
    return ''
  }
}

function safeFetchExtendOktaLoginToken(): string {
  try {
    const rawToken = localStorage.getItem(EXTEND_ACCESS_TOKEN_KEY)
    return rawToken ?? ''
  } catch (err) {
    return ''
  }
}

import type { ReactElement, FC } from 'react'
import React, { useState, createRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { ChatMessage } from './chat-message'
import ChatKaleyHead from './chat-kaley-head'
import type { ChatMessage as ChatMessageType } from '../../../types/chat-message'

export const ANIMATION_DURATION = 300

interface ChatMessageGroupProps {
  group: ChatMessageType[]
  setChatInputHeight: React.Dispatch<React.SetStateAction<number>>
}

const ChatMessageGroup: FC<ChatMessageGroupProps> = ({ group, setChatInputHeight }) => {
  const botWrapperRef = createRef<HTMLDivElement>()

  // `moveHead` holds the state of when to move the chat head. Triggered whenever
  // `group` changes and animations are complete. Reset in <ChatKaleyHead />
  const [moveHead, setMoveHead] = useState(false)

  useEffect(() => {
    const timeout = setTimeout(() => {
      setMoveHead(true)
    }, ANIMATION_DURATION + 100)

    return () => {
      clearTimeout(timeout)
    }
  }, [group])

  const { bot } = group[0]

  const renderGroup = (): ReactElement[] => {
    return group.map((message, index) => {
      const { bot: isBot, ...messageProps } = message

      const lastIndex = index === group.length - 1
      return (
        <ChatMessage
          {...messageProps}
          bot={isBot}
          lastIndex={lastIndex}
          setMoveHead={setMoveHead}
          duration={ANIMATION_DURATION}
          setChatInputHeight={setChatInputHeight}
        />
      )
    })
  }

  if (bot) {
    return (
      <div style={{ alignSelf: 'flex-start', width: '100%' }}>
        <BotMessagesWrapper ref={botWrapperRef}>
          <ChatKaleyHead moveHead={moveHead} setMoveHead={setMoveHead} />
          <div style={{ flexDirection: 'column' }}>{renderGroup()}</div>
        </BotMessagesWrapper>
      </div>
    )
  }

  return <UserMessagesWrapper>{renderGroup()}</UserMessagesWrapper>
}

const UserMessagesWrapper = styled.div({
  marginBottom: '10px',
  maxWidth: '75%',
})

const BotMessagesWrapper = styled.div({
  width: '75%',
  marginBottom: '10px',
  alignSelf: 'flex-start',
  display: 'flex',
})

export type { ChatMessageGroupProps }
export { ChatMessageGroup }

import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@extend/client-helpers'
import { COLOR } from '@customers-ui'
import svgs from '../../../lib/svgs'
import type { Locale } from '../../../types/localization'

export interface ClaimFulfillmentHeadingProps {
  isRepair?: boolean
  repairStatus?: string
  firstName: string
  isCanada?: boolean
  locale?: Locale
  setLocale?: (currentLocale: Locale, newLocale: Locale) => void
}
export const pageText = {
  'en-US': {
    greeting: {
      approved: ['Great news, ', 'your claim is approved!'],
      shipped: ['Hey there, ', 'your product on its way to be repaired.'],
      progress: ['Guess what, ', 'your product is being repaired!'],
      complete: ['Heads up, ', 'your repaired product is on its way.'],
      delivered: ['Congrats, ', 'your repaired product was delivered!'],
    },
    languageButton: 'Voir en français',
    claimTypes: {
      replacement: 'Product Replacement',
      repair: 'Product Repair',
    },
  },
  'en-CA': {
    greeting: {
      approved: ['Great news, ', 'your claim is approved!'],
      shipped: ['Hey there, ', 'your product on its way to be repaired.'],
      progress: ['Guess what, ', 'your product is being repaired!'],
      complete: ['Heads up, ', 'your repaired product is on its way.'],
      delivered: ['Congrats, ', 'your repaired product was delivered!'],
    },
    languageButton: 'Voir en français',
    claimTypes: {
      replacement: 'Product Replacement',
      repair: 'Product Repair',
    },
  },
  'fr-CA': {
    greeting: {
      approved: ['Bonne nouvelle, ', 'votre réclamation a été approuvée!'],
      shipped: [],
      progress: [],
      complete: [],
      delivered: [],
    },
    languageButton: 'View in English',
    claimTypes: {
      replacement: 'Remplacement de produit',
      repair: '',
    },
  },
}

export const ClaimFulfillmentHeading: FC<ClaimFulfillmentHeadingProps> = ({
  isRepair = false,
  repairStatus = 'approved',
  firstName,
  isCanada,
  locale = 'en-US',
  setLocale = () => {},
}) => {
  const { languageButton, claimTypes, greeting } = pageText[locale]
  const greetingText = repairStatus
    ? (greeting as Record<string, string[]>)[repairStatus]
    : greeting.approved

  const handleLocaleSwitch = (currentLocale: Locale): void => {
    switch (currentLocale) {
      case 'en-CA':
        setLocale(currentLocale, 'fr-CA')
        break
      case 'fr-CA':
        setLocale(currentLocale, 'en-CA')
        break
      default:
        break
    }
  }
  return (
    <HeadingWrapper>
      {isCanada && (
        <LanguageButton
          data-cy="claim-fullfilment-language-toggle"
          onClick={() => handleLocaleSwitch(locale)}
        >
          {languageButton}
        </LanguageButton>
      )}
      <MainHeadingWrapper>
        <MainHeading data-cy="claim-fulfillment-heading">
          <svgs.Checkmark />
          {greetingText[0] + firstName}, <Break />
          {greetingText[1]}
        </MainHeading>
        <ClaimType>{isRepair ? claimTypes.repair : claimTypes.replacement}</ClaimType>
      </MainHeadingWrapper>
    </HeadingWrapper>
  )
}

const HeadingWrapper = styled.div({
  display: 'flex',
  position: 'relative',
  '& svg': {
    display: 'none',
    marginTop: 8,
    marginRight: 16,
  },
  flexDirection: 'column',
  [bp.md]: {
    position: 'initial',
    alignItems: 'flex-end',
    '& svg': {
      display: 'block',
    },
  },
})

const Break = styled.br({
  display: 'none',
  [bp.sm]: {
    display: 'block',
  },
})

const MainHeadingWrapper = styled.div({
  display: 'flex',
  width: '100%',
  flexDirection: 'column',
  [bp.md]: {
    justifyContent: 'space-between',
    flexDirection: 'row',
  },
})

const MainHeading = styled.h1<{ centered?: boolean }>(
  {
    display: 'flex',
    margin: 0,
    fontSize: 26,
    lineHeight: '35px',
    [bp.md]: {
      lineHeight: '45px',
      fontSize: 33,
    },
  },
  ({ centered }) => ({
    textAlign: centered ? 'center' : 'left',
  }),
)

const ClaimType = styled.div({
  fontSize: '11.2px',
  border: `1.4px solid`,
  padding: '5px 15px',
  borderRadius: '15.05px',
  fontWeight: 'bold',
  height: 'fit-content',
  marginTop: '15px',
  textAlign: 'center',
  color: COLOR.GREEN[500],
  borderColor: COLOR.GREEN[500],
  [bp.md]: {
    borderWidth: 2,
    marginTop: '4px',
    fontSize: 16,
    borderRadius: '21.5px',
  },
})

const LanguageButton = styled.button({
  fontSize: '16px',
  color: COLOR.BLUE[700],
  textAlign: 'end',
  [bp.md]: {
    fontSize: 16,
    borderRadius: '21.5px',
    marginBottom: '15px',
  },
  [bp.lg]: {
    right: 116,
  },
})

import type {
  CategoryContract,
  ContractStatus,
  ContractsSearchIndividual,
  ExtendedWarrantyContract,
} from '../../types/contract'
import type { Claim } from '../../types/claim'

export const NON_TRANSFERABLE_STATUSES: ContractStatus[] = [
  'cancelled',
  'expired',
  'refund_accepted',
  'refund_paid',
  'refund_requested',
  'voided',
  'terminated',
]

export const getCanTransfer = ({ contract, claims, hasActiveClaim }: {contract: ContractsSearchIndividual, claims?: Claim[]; hasActiveClaim?: boolean}): boolean => {
  if (contract) {
    if (
      new Date().getTime() <
      (((contract as ExtendedWarrantyContract) || (contract as CategoryContract)).coverage
        ?.starts || 0)
    ) {
      return false
    }

    if (NON_TRANSFERABLE_STATUSES.includes(contract.status)) {
      return false
    }

    if (contract.purchaseCurrency === 'CAD') {
      return false
    }
  }

  if (hasActiveClaim) {
    return false
  }

  return !claims?.some(
    (claim: Claim) =>
      claim.status === 'approved' ||
      claim.status === 'review' ||
      claim.status === 'pending_adjudication',
  )
}

import { createApi } from '@reduxjs/toolkit/query/react'
import type { Content, ShippingProtectionContent } from '@customers-api-client'
import { baseQuery } from '../base-query'
import type { CcsParams, CharityContent, CharityContentParams, SpContentParams } from './types'
import { orderCcsParams } from './order-ccs-params'

export const contentApi = createApi({
  baseQuery,
  reducerPath: 'Content',
  tagTypes: ['Content'],
  endpoints: (build) => ({
    getContent: build.query<Content, CcsParams>({
      query: (ccsParams) => ({
        url: `content/offers`,
        params: orderCcsParams(ccsParams),
        method: 'GET',
        headers: {
          Accept: `application/json; version=latest`,
        },
      }),
      providesTags: (_, _err) => [{ type: 'Content' }],
    }),
    getShippingProtectionContent: build.query<ShippingProtectionContent, SpContentParams>({
      query: (params) => {
        return {
          url: 'content/shipping-protection',
          method: 'GET',
          params,
          headers: {
            Accept: `application/json;version=latest`,
          },
        }
      },
      providesTags: (_, _err) => [{ type: 'Content' }],
    }),
    getCharityContent: build.query<CharityContent, CharityContentParams>({
      query: (params) => {
        return {
          url: 'content/charity',
          method: 'GET',
          params,
          headers: {
            Accept: `application/json;version=latest`,
          },
        }
      },
      providesTags: (_, _err) => [{ type: 'Content' }],
    }),
  }),
})

export const {
  useLazyGetContentQuery,
  useGetShippingProtectionContentQuery,
  useGetCharityContentQuery,
} = contentApi

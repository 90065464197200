import React, { useState, useEffect } from 'react'
import type { FC, ReactElement } from 'react'
import { createPortal } from 'react-dom'
import { useHistory, useLocation } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import type { ClaimPhotosGetResponse, ClaimStatus } from '@customers-api-client'
import { Modal, ModalController, COLOR, LoadingText, celebrate } from '@extend/zen'
import { bp } from '@customers-ui'
import styled from '@emotion/styled'
import { useGetStoreConsumerQuery } from '@customers-api-rtk-query'
import type { Claim } from '../../types/claim'
import { images } from '../../lib/assets'
import { hasUnmetPhotoRequirements } from '../../lib/helper-functions'

interface ClaimResultModalProps {
  claim: Claim
  onDismiss: () => void
  isModalOpen: boolean
  isPollingClaim?: boolean
  photoData?: ClaimPhotosGetResponse
}

const ClaimResultModal: FC<ClaimResultModalProps> = ({
  claim,
  onDismiss,
  isModalOpen,
  isPollingClaim = false,
  photoData,
}) => {
  const history = useHistory()
  const location = useLocation()
  const [secondsLeft, setSecondsLeft] = useState<number>(5)
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(false)
  const [showPhotoUploadReview, setShowPhotoUploadReview] = useState<boolean>(false)
  const [shouldRunAnalyzingClaimTimer, setShouldRunAnalyzingClaimTimer] = useState<boolean>(true)

  const { data: store } = useGetStoreConsumerQuery(
    { storeId: claim?.sellerId },
    { skip: !claim?.sellerId },
  )

  const doesClaimHaveUnmetPhotoRequirements =
    !isPollingClaim && hasUnmetPhotoRequirements(claim, photoData)
  const hasAdjudicatedClaim = !!claim && (claim?.status as ClaimStatus) !== 'pending_adjudication'

  const decreaseSeconds = (): void => {
    setSecondsLeft((prev) => {
      if (prev > 0) {
        return prev - 1
      }
      return 5
    })
  }

  /*
   * Start with the initial analyzing state for 5 seconds.
   * If the claim has unmet photo requirements, show the photo review section.
   * If there's no claim that's been adjudicated yet, continue showing the analyzing state.
   * Once the claim has ben adjudicated, display the result with the countdown starting back at 5 seconds.
   */

  useEffect(() => {
    if (isModalOpen) {
      const timer = setInterval(decreaseSeconds, 1000)

      // starting the timer for the analyzing state
      if (shouldRunAnalyzingClaimTimer && secondsLeft > 0) {
        return () => clearInterval(timer)
      }

      // once our timer reaches 0, we need to perform a series of checks to determine what to display next/restart timer
      if (secondsLeft <= 0) {
        // if the claim has unmet photo requirements and we're not already displaying the review state, restart the timer and show the review state
        if (!showPhotoUploadReview && doesClaimHaveUnmetPhotoRequirements) {
          setShouldRunAnalyzingClaimTimer(false)
          setShowPhotoUploadReview(true)
          setIsTimerRunning(true)
          setSecondsLeft(5)
          // if the claim has not been adjudicated and we are still running the analyzing state, restart the timer at 1 second to wait for the claim to be adjudicated
        } else if (shouldRunAnalyzingClaimTimer && !hasAdjudicatedClaim) {
          setSecondsLeft(1)
          setIsTimerRunning(true)
          // if the claim has been adjudicated and we are showing the analyzing state, restart the timer at 5 seconds to show the result
        } else if (shouldRunAnalyzingClaimTimer) {
          setSecondsLeft(5)
          setIsTimerRunning(true)
          setShouldRunAnalyzingClaimTimer(false)
          // once we reset the timer the final time, this countdown will determine when we redirect the user to the my claims page
        } else {
          setIsTimerRunning(false)
          handleRedirectToMyClaims()
        }
      }

      return () => clearInterval(timer)
    }

    return () => {
      setShowPhotoUploadReview(false)
      setSecondsLeft(5)
      setIsTimerRunning(true)
    }
  }, [
    secondsLeft,
    isModalOpen,
    hasAdjudicatedClaim,
    showPhotoUploadReview,
    history,
    doesClaimHaveUnmetPhotoRequirements,
    isPollingClaim,
    onDismiss,
    shouldRunAnalyzingClaimTimer,
    location.pathname,
  ])

  const handleRedirectToMyClaims = (): void => {
    const isOnMyClaimsPage = location.pathname.includes('/my_claims')
    if (!isOnMyClaimsPage) {
      history.push('/my_claims')
      window.location.reload()
    } else {
      window.location.reload()
    }
  }

  const isClaimAnalyzing =
    (!claim || !hasAdjudicatedClaim || shouldRunAnalyzingClaimTimer) && !showPhotoUploadReview

  return createPortal(
    <ModalController isOpen={isModalOpen}>
      <Modal
        heading={isClaimAnalyzing ? 'Claim Analysis' : 'Claim Result'}
        size="sm"
        onDismissRequest={onDismiss}
        primaryButtonProps={
          (hasAdjudicatedClaim || showPhotoUploadReview) && !shouldRunAnalyzingClaimTimer
            ? {
                text: `Continue ${
                  isTimerRunning ? `(0:${secondsLeft.toString().padStart(2, '0')})` : ''
                }`,
                onClick: handleRedirectToMyClaims,
                emphasis: 'medium',
                'data-cy': 'claim-result-modal-continue-button',
              }
            : undefined
        }
        isMobile={isMobile}
      >
        <ContentWrapper data-cy="claim-result-content-wrapper">
          {isClaimAnalyzing && (
            <>
              <Image src={images.analyzingGif} data-cy="analyzing-claim-icon" />
              <StatusText data-cy="analyzing-claim-status-text">
                Analyzing your <LoadingText text="claim" />
              </StatusText>
            </>
          )}
          {showPhotoUploadReview && !hasAdjudicatedClaim && doesClaimHaveUnmetPhotoRequirements && (
            <>
              <Image src={images.reviewIcon2} data-cy="review-claim-icon" />
              <StatusText data-cy="photos-required-claim-status-text">
                We need more information
              </StatusText>
              <MessageBody data-cy="photos-required-claim-message-body">
                Photos are required for this damage.
              </MessageBody>
            </>
          )}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'approved' && (
            <>
              {celebrate()}
              <Image src={images.approvedIcon2} data-cy="approved-claim-icon" />
              <StatusText data-cy="approved-claim-status-text">Your claim is approved!</StatusText>
              <MessageBody data-cy="approved-claim-message-body">
                Click &quot;Continue&quot; to proceed with the fulfillment process.
              </MessageBody>
            </>
          )}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'review' && (
            <>
              <Image src={images.reviewIcon2} data-cy="review-claim-icon" />
              <StatusText data-cy="review-claim-status-text">
                Your claim is under review.
              </StatusText>
              <MessageBody data-cy="review-claim-message-body">
                We are reviewing your claim and will reach out via email soon.
              </MessageBody>
            </>
          )}
          {!shouldRunAnalyzingClaimTimer && claim?.status === 'denied' && (
            <>
              <Image src={images.deniedIcon2} data-cy="denied-claim-icon" />
              <StatusText data-cy="denied-claim-status-text">Your claim is denied.</StatusText>
              <MessageBody data-cy="denied-claim-message-body">
                {getDenialMessage(claim, store?.name || '')}
              </MessageBody>
            </>
          )}
        </ContentWrapper>
      </Modal>
    </ModalController>,
    document.body,
  )
}

const getDenialMessage = (claim: Claim, storeName?: string): ReactElement => {
  if (claim?.validationReason === 'mfr_warranty_active') {
    return (
      <span>
        Your claim should still be covered under the product manufacturer warranty. Contact{' '}
        {storeName ?? 'manufacturer'} for help.
      </span>
    )
  }
  return (
    <span>
      We are unable to fulfill your claim.<br></br>Please reach out with further questions.
    </span>
  )
}

const ContentWrapper = styled.div({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignSelf: 'stretch',
  width: '100%',
  gap: '16px',
  marginTop: '40px',
  [bp.mobile]: {
    height: '60vh',
    flex: '1 0 0',
  },
  [bp.desktop]: {
    height: '304px',
  },
})

const Image = styled.img({
  width: '40px',
  height: '40px',
})

const StatusText = styled.div({
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '28px',
  color: COLOR.NEUTRAL[1000],
  textAlign: 'center',
  wordWrap: 'break-word',
  maxWidth: '100%',
})

const MessageBody = styled.div({
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: COLOR.NEUTRAL[1000],
  textAlign: 'center',
})

export { ClaimResultModal }

import React from 'react'

const CustomerHeart: React.FC = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="57" height="52" viewBox="0 0 57 52">
      <g
        fill="none"
        fillRule="evenodd"
        stroke="none"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="3"
      >
        <path
          stroke="#224BC5"
          d="M26.5 48.27S9.79 31.467 4.51 25.575C1.962 22.734.145 19.155.145 15.042.146 6.815 6.815.146 15.042.146c4.608 0 8.726 2.094 11.458 5.383A14.858 14.858 0 0137.958.146c8.227 0 14.896 6.669 14.896 14.896 0 4.113-1.817 7.693-4.363 10.532C43.21 31.466 26.5 48.271 26.5 48.271z"
          transform="translate(-735 -1493) translate(737 1495)"
        />
        <path
          stroke="#27AEE4"
          d="M6 16.313C6 10.627 10.627 6 16.313 6"
          transform="translate(-735 -1493) translate(737 1495)"
        />
      </g>
    </svg>
  )
}

export default CustomerHeart

import type { FC, ComponentProps } from 'react'
import React from 'react'
import { Textarea as ChakraTextArea } from '@chakra-ui/react'
import { COLOR } from '@extend/zen'

import { FormControl } from '../form-control'

interface TextAreaProps extends ComponentProps<typeof ChakraTextArea> {
  className?: string
  dataQa?: string
  id?: string
  label?: string
  errorMessage?: string
  hint?: string
}

const TextArea: FC<TextAreaProps> = ({
  className,
  dataQa,
  id,
  label,
  errorMessage,
  isDisabled,
  isRequired,
  isInvalid,
  hint,
  ...props
}) => (
  <FormControl
    {...{
      className,
      id,
      label,
      errorMessage,
      isDisabled,
      isInvalid,
      hint,
    }}
    isAsteriskVisible={isRequired}
  >
    <ChakraTextArea
      data-qa={dataQa}
      {...TextAreaStyles}
      {...props}
      {...{ id, isDisabled, isInvalid }}
    />
  </FormControl>
)

const TextAreaStyles = {
  borderRadius: 16,
  _focus: {
    border: `2px solid ${COLOR.BLUE[800]}`,
    _invalid: {
      border: `2px solid ${COLOR.RED[700]}`,
    },
  },
  _invalid: {
    border: `1px solid ${COLOR.RED[700]}`,
  },
  sx: {
    '&.chakra-textarea': {
      resize: 'none',
      paddingInlineStart: 16,
      paddingInlineEnd: 16,
      paddingTop: 12,
      paddingBottom: 12,
    },
  },
}

export { TextArea }

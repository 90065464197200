import type { FC } from 'react'
import React from 'react'
import styled from '@emotion/styled'
import { bp } from '@customers-ui'
import type { ContractsSearchResponse } from '../../types/contract'
import { sortAndGroupContracts } from '../../lib/helper-functions'
import { sortAndGroupEMSData } from '../../lib/ems-utils'
import { EntitlementSearchByOrdersResponse } from '@customers-api-rtk-query'
import { ContractList } from './contract-list'

type ContractListContainerProps = ContractsSearchResponse & {
  emsOrdersResponse: EntitlementSearchByOrdersResponse[]
}

const ContractListContainer: FC<ContractListContainerProps> = ({
  contracts,
  emsOrdersResponse,
}) => {
  const groupedContracts = sortAndGroupContracts([...contracts])
  const groupedEmsData = sortAndGroupEMSData(emsOrdersResponse)

  return (
    <ContractListWrapper data-cy="contracts-list-wrapper">
      {(!!contracts.length || !!emsOrdersResponse.length) && (
        <ContractList
          groupedContracts={groupedContracts}
          groupedEmsData={groupedEmsData}
          data-cy="contract-list-active"
        />
      )}
    </ContractListWrapper>
  )
}

const ContractListWrapper = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
  [bp.mobile]: {
    flexDirection: 'column',
    justifyContent: 'center',
    gap: '16px',
  },
  [bp.desktop]: {
    flexDirection: 'column',
    justifyContent: 'flex-start',
    gap: '24px',
  },
})

export { ContractListContainer }

import { PrecheckResponse, PrecheckErrorCode } from '@extend-services/adjudication-management'
import { BadgeProps } from '@extend/zen'
import { toTitleCase } from '../../utils/string-utils'

interface DisplayDetails {
  badgeDetails: string
  badgeColor: BadgeProps['color']
  isClaimFilingDisabled: boolean
  fileClaimTooltip: string
  subBadgeText?: string
}
export const getDisplayDetails = ({
  precheck,
  status,
  type,
}: {
  precheck?: PrecheckResponse
  status?: string
  type?: string
}): DisplayDetails => {
  if (precheck?.status === 'success') {
    return {
      badgeDetails: 'Active',
      badgeColor: 'green',
      isClaimFilingDisabled: false,
      fileClaimTooltip: '',
    }
  }

  if (precheck?.status === 'failure') {
    const errorMessage = getValidationErrorMessages(status || '')[precheck.validationError]
    if (errorMessage) {
      return type && errorMessage?.emsTypes?.[type]
        ? errorMessage.emsTypes[type]
        : errorMessage.default
    }
  }

  return getValidationErrorMessages('unknown').unknown.default
}

export const getValidationErrorMessages = (
  status: string,
): Record<
  PrecheckErrorCode,
  {
    default: DisplayDetails
    emsTypes?: Record<string, DisplayDetails>
  }
> => {
  return {
    invalid_contract_status: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'This contract is not eligible to file a claim',
      },
    },
    active_claim_found: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'green',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'There is currently an open claim for this contract',
        subBadgeText: 'Claim in progress',
      },
    },
    no_coverage_remaining: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip:
          'You have used your full limit of liability on this contract through already fulfilled claims',
      },
    },
    expired: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your contract coverage has expired',
      },
      emsTypes: {
        shipping_protection: {
          badgeDetails: toTitleCase(status),
          badgeColor: 'neutral',
          isClaimFilingDisabled: true,
          fileClaimTooltip: 'Your contract expires 60 days after package delivery',
        },
      },
    },
    not_yet_active: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'yellow',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your coverage is not yet active',
      },
      emsTypes: {
        shipping_protection: {
          badgeDetails: toTitleCase(status),
          badgeColor: 'yellow',
          isClaimFilingDisabled: true,
          fileClaimTooltip: 'Your contract begins upon package shipped',
        },
      },
    },
    mfr_warranty_active: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'blue',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Your contract activates after manufacturer warranty ends',
      },
    },
    pending_photo_uploads: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'green',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'There is currently an open claim for this contract that requires photos',
        subBadgeText: 'Claim requires photos',
      },
    },
    service_type_invalid: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Filing a claim is currently unavailable, contact support for help',
      },
    },
    unknown: {
      default: {
        badgeDetails: toTitleCase(status),
        badgeColor: 'neutral',
        isClaimFilingDisabled: true,
        fileClaimTooltip: 'Filing a claim is currently unavailable, contact support for help',
      },
    },
  }
}
